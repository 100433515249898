import { cloneElement } from 'react'
import { useLocation, useOutlet } from 'react-router-dom'
import { makeStyles } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import {
  AcAppBackground,
  IAcAppBackground,
} from 'src/components/core/ac-app-background/ac-app-background'

import { ReactComponent as BuurtboerLogo } from '@assets/logos/buurtboer-logo-horizontal.svg'
import {
  AcBox,
  AcCard,
  AcColumn,
  AcContainer,
  AcNotifications,
} from '@components'
import { useBreakpoints } from '@hooks'

interface IAcOnboardingLayout {
  withCard?: boolean
  variant?: IAcAppBackground['variant']
}

const AnimatedOutlet = () => {
  const location = useLocation()
  const element = useOutlet()

  return (
    <AnimatePresence
      mode="wait"
      initial={true}>
      {element && cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export const AcOnboardingLayout = ({
  withCard = true,
  variant = 'branded',
}: IAcOnboardingLayout): JSX.Element => {
  const { isMobile } = useBreakpoints()
  const cardHeight = isMobile ? `calc(100vh - 112px)` : 0

  return (
    <AcAppBackground variant={variant}>
      <AcBox p={5}>
        <BuurtboerLogo />
      </AcBox>
      <AcContainer>
        <AcColumn
          justifyContent={'center'}
          sx={{ minHeight: isMobile ? 0 : '80vh' }}>
          {withCard ? (
            <AcCard
              padding="lg"
              maxWidth={448}
              center
              sx={{
                width: '100%',
                minHeight: cardHeight,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <AnimatedOutlet />
            </AcCard>
          ) : (
            <AnimatedOutlet />
          )}
        </AcColumn>
      </AcContainer>
      <AcNotifications />
    </AcAppBackground>
  )
}
