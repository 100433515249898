import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import type { IOrderDetails } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcAlert,
  AcBackButton,
  AcCard,
  AcColumn,
  AcContainer,
  AcLoaderPulse,
  AcTabs,
  AcTypography,
} from '@components'
import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks'
import { AcOrderDetailsMenuTab, AcOrderDetailsOverviewTab } from '@partials'

export const OrderDetailView = observer(() => {
  const params = useParams()
  const { orders } = useStore()
  const [loadingOrder, setLoadingOrder] = useState(true)
  const [orderDetails, setOrderDetails] = useState<IOrderDetails | null>(null)
  const [orderError, setOrderError] = useState('')

  const fetchOrderDetails = async (id: number) => {
    orders
      .fetchSingleOrder(id)
      .then(res => setOrderDetails(res))
      .catch(err => setOrderError(err.message))
      .finally(() => setLoadingOrder(false))
  }

  useEffect(() => {
    if (params.id) {
      fetchOrderDetails(parseInt(params.id))
    }
  }, [params.id])

  const renderTabs = useMemo(() => {
    if (!orderDetails) return null
    return [
      {
        title: LABELS.OVERVIEW,
        content: <AcOrderDetailsOverviewTab {...orderDetails} />,
      },
      {
        title: LABELS.WEEK_MENU,
        content: <AcOrderDetailsMenuTab {...orderDetails} />,
      },
    ]
  }, [params.id, orderDetails])

  const renderPageContent = useMemo(() => {
    if (loadingOrder) return null
    if (!orderDetails) return null
    if (
      orderDetails?.status === KEYS.STATUS_PENDING ||
      orderDetails?.status === KEYS.STATUS_CANCELLED
    )
      return <AcOrderDetailsOverviewTab {...orderDetails} />
    if (renderTabs?.length) return <AcTabs tabs={renderTabs} />
  }, [orderDetails?.status, loadingOrder])

  return (
    <AcContainer>
      <AcBackButton
        variant="text"
        withIcon
        href={NAVIGATE_TO.ADMIN_ORDERS}
      />
      {loadingOrder && <AcLoaderPulse py={5} />}
      {orderError && (
        <AcColumn mt={6}>
          <AcAlert severity="error">{orderError}</AcAlert>
        </AcColumn>
      )}
      {!loadingOrder && orderDetails && (
        <AcColumn mt={6}>
          <AcCard cardColor="pink">
            <AcTypography component="h1">
              Bestelling week {orderDetails?.week}
            </AcTypography>
            {orderDetails?.status !== KEYS.STATUS_PENDING && (
              <AcTypography removePadding>
                De bestelling kan niet meer gewijzigd worden
              </AcTypography>
            )}
          </AcCard>
          {renderPageContent}
        </AcColumn>
      )}
    </AcContainer>
  )
})
