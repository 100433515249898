import { useMemo } from 'react'

import { AcButton, AcColumn, AcTable, AcTypography } from '@components'
import { LABELS } from '@constants'
import { IOrderSummary } from '@typings'

import { AcOrdersTableRow } from '../ac-orders-table-row/ac-orders-table-row'

export interface IOrdersTable {
  year: number
  rows?: IOrderSummary[]
}

export const AcOrdersTable = ({ year, rows }: IOrdersTable) => {
  const renderTableRows = useMemo(() => {
    if (!rows?.length) return []
    return rows.map(row => <AcOrdersTableRow {...row} />)
  }, [rows])

  if (!rows?.length) {
    return (
      <AcColumn>
        <AcTypography>
          We hebben geen bestellingen gevonden voor {year}.
        </AcTypography>
      </AcColumn>
    )
  }

  return (
    <AcColumn>
      <AcTable rows={renderTableRows} />
      <AcButton
        variant="secondary"
        sx={{ width: 'fit-content' }}>
        {LABELS.LOAD_MORE}
      </AcButton>
    </AcColumn>
  )
}
