import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IWeeksSingleLunchersResponse, IWeeksSingleResponse } from '@typings'

export class WeeksApi extends AxiosBaseApi {
  getWeek = (week: string) =>
    this.api
      .get<{
        data: { data: IWeeksSingleResponse }
      }>(ENDPOINTS.WEEKS.SINGLE(week))
      .then(response => response.data)
      .catch(err => err)

  getWeekLunchers = (week: string) =>
    this.api
      .get<{
        data: { data: IWeeksSingleLunchersResponse }
      }>(ENDPOINTS.WEEKS.SINGLE_LUNCHERS(week))
      .then(response => response.data)

  getAttendanceWeeks = () =>
    this.api
      .get<{
        data: { data: IWeeksSingleLunchersResponse }
      }>(ENDPOINTS.LUNCHER.WEEKS)
      .then(response => response.data)
}
