import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IOrderDetails, IOrderSummary } from '@typings'

export class SpecificationsApi extends AxiosBaseApi {
  index = () =>
    this.api
      .get<{
        data: { data: IOrderSummary[] }
      }>(ENDPOINTS.SPECIFICATIONS.INDEX)
      .then(response => response?.data?.data)

  get_single = (id: number) =>
    this.api
      .get<{
        data: { data: IOrderDetails }
      }>(ENDPOINTS.SPECIFICATIONS.SINGLE(id))
      .then(response => response.data?.data)
}
