import { useState } from 'react'

import { AcBox, AcColumn, AcListFilters, AcListResults } from '@components'

export const AcList = () => {
  const [query, setQuery] = useState('')
  const [filters, setFilters] = useState([])

  return (
    <AcColumn>
      <AcListFilters
        query={query}
        setQuery={setQuery}
        filters={filters}
        setFilters={setFilters}
      />
      <AcListResults />
    </AcColumn>
  )
}
