import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import {
  autoLoad,
  getFromStorage,
  removeFromStorage,
  saveToStorage,
} from '@helpers'
import { extractAPIFieldErrors } from '@helpers'
import { Store } from '@stores'
import {
  IErrorResponse,
  ILoginUserPayload,
  IRegisterUserPayload,
} from '@typings'

import { TOASTERS } from '../constants/toasters.constants'

export class AuthStore {
  store: Store
  access_token: string

  loading: boolean
  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.access_token = ''

    autoLoad(this, 'token')
  }

  @computed
  get watchLoading(): boolean {
    return this.loading
  }

  @action
  setAccessToken = (token: string) => {
    this.store.set(STORE_KEYS.AUTH, 'access_token', token)
  }

  @computed
  get is_authenticated() {
    return this.access_token !== '' || getFromStorage('access_token')
  }

  @action
  login = async (data: ILoginUserPayload) => {
    this.store.set(STORE_KEYS.AUTH, 'loading', true)
    try {
      const res = await this.store.api.auth.login(data)
      this.setAccessToken(res.meta.token)
      saveToStorage('access_token', res.meta.token)

      await this.store.user.whoAmI()
      return Promise.resolve(res.data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  register = async (data: IRegisterUserPayload) => {
    this.store.set(STORE_KEYS.AUTH, 'loading', true)
    try {
      const res = await this.store.api.auth.register(data)
      this.setAccessToken(res.data.access_token)
      saveToStorage('access_token', res.data.access_token)
      return Promise.resolve(res)
    } catch (e) {
      const err: IErrorResponse = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  getRegistrationFields = async (code: string, signature: string) => {
    try {
      const res = await this.store.api.auth.getRegistrationFields(
        code,
        signature,
      )
      return Promise.resolve(res)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  postRequestPasswordReset = async (email: string) => {
    try {
      await this.store.api.auth.requestPasswordReset({ email })
      this.store.toaster.success(TOASTERS.AUTH.PASSWORD_RESET_REQUESTED)
      return true
    } catch (e) {
      this.store.toaster.error(TOASTERS.GENERIC_FAIL)
      return false
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  postResetPassword = async (data: any) => {
    this.store.set(STORE_KEYS.AUTH, 'loading', true)
    try {
      const res = await this.store.api.auth.resetPassword(data)
      return Promise.resolve(res)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  postChangePassword = async (data: any) => {
    this.store.set(STORE_KEYS.AUTH, 'loading', true)
    try {
      return await this.store.api.auth.changePassword(data)
    } catch (e: any) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  postLogout = () => {
    this.store.resetStores()
    this.setAccessToken('')
    removeFromStorage('access_token')
    removeFromStorage('activeRole')
  }

  @action
  requestPasswordReset = async (email: string) => {
    this.store.set(STORE_KEYS.AUTH, 'loading', true)
    try {
      await this.store.api.auth.requestPasswordReset({ email })
      return Promise.resolve({ success: true })
    } catch (error) {
      const err: IErrorResponse = extractAPIFieldErrors(
        error as Error | AxiosError,
      )
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.AUTH, 'loading', false)
    }
  }

  @action
  reset = () => {
    this.access_token = ''
    this.loading = false
  }
}
