import { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { usePWAInstall } from '@hooks'
import { useStore } from '@hooks'

import { ROUTER } from './routes.routes'

export const AcRouter = observer(() => {
  const { user, auth } = useStore()
  const { saveEvent } = usePWAInstall()
  const router = createBrowserRouter(ROUTER)

  useEffect(() => {
    if (auth.is_authenticated) {
      user.whoAmI()
    }

    window.addEventListener('beforeinstallprompt', e => {
      console.log('PWA Install prompt available')
      e.preventDefault()
      saveEvent(e as any)
    })
  }, [])

  return <RouterProvider router={router} />
})
