import { useNavigate } from 'react-router-dom'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import LogoutIcon from '@mui/icons-material/Logout'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { NAVIGATE_TO } from '@routes'

import {
  AcAvatar,
  AcColumn,
  AcDivider,
  AcRoleSwitcher,
  AcRow,
  AcTypography,
} from '@components'
import { DIALOGS, LABELS, THEME_COLORS } from '@constants'
import { useStore } from '@hooks'

const ICON_SIZE = 'small'
const ICON_COLOR = THEME_COLORS.BRAND_BEIGE
const EXT_ICON_COLOR = THEME_COLORS.BRAND_LIGHT_GREEN

const externalLinks = [
  {
    id: 1,
    href: 'https://debuurtboer.nl/faq/',
    label: 'FAQ',
    icon: (
      <QuizOutlinedIcon
        fontSize={ICON_SIZE}
        sx={{ color: ICON_COLOR }}
      />
    ),
  },
  {
    id: 2,
    href: 'https://debuurtboer.nl/contact/',
    label: 'Service contactgegevens',
    icon: (
      <ChatOutlinedIcon
        fontSize={ICON_SIZE}
        sx={{ color: ICON_COLOR }}
      />
    ),
  },
  {
    id: 3,
    href: 'https://debuurtboer.nl/algemenevoorwaarden/',
    label: 'Algemene voorwaarden',
    icon: (
      <ReceiptLongOutlinedIcon
        fontSize={ICON_SIZE}
        sx={{ color: ICON_COLOR }}
      />
    ),
  },
]

export interface IAcCustomerSettingsMenu {
  handleClose: () => void
}

export const AcCustomerSettingsMenu = ({
  handleClose,
}: IAcCustomerSettingsMenu) => {
  const { user, dialog, auth, toaster } = useStore()
  const navigate = useNavigate()

  const pageLinks = [
    {
      id: 1,
      onClick: () => dialog.open(DIALOGS.CHANGE_PASSWORD),
      label: 'Wachtwoord aanpassen',
      icon: (
        <KeyOutlinedIcon
          fontSize={ICON_SIZE}
          sx={{ color: ICON_COLOR }}
        />
      ),
    },
    {
      id: 2,
      onClick: () => {
        auth.postLogout()
        navigate(NAVIGATE_TO.LOGIN)
        toaster.success({ content: 'Succesvol uitgelogd.' })
      },
      label: 'Uitloggen',
      icon: (
        <LogoutIcon
          fontSize={ICON_SIZE}
          sx={{ color: ICON_COLOR }}
        />
      ),
    },
  ]

  return (
    <>
      <AcColumn
        p={4}
        gap={5}>
        <AcRow
          alignItems="center"
          spacing="lg">
          <AcAvatar
            alt={user.user?.first_name}
            src="/static/images/avatar/2.jpg"
            sx={{
              width: 56,
              height: 56,
            }}
          />
          <AcColumn
            gap={0}
            sx={{ color: ICON_COLOR }}>
            <AcTypography
              size="lg"
              component="h3"
              removePadding>
              {user.user?.email}
            </AcTypography>
            <AcTypography component="span">
              {user.user?.customer?.name}
            </AcTypography>
            <AcTypography
              size="sm"
              component="span"
              bold>
              {LABELS.USER_ROLES}:{' '}
              {user.watchUserRoles
                ?.map(role => LABELS[role.name as keyof typeof LABELS])
                .join(', ')}
            </AcTypography>
          </AcColumn>
        </AcRow>
        {user.isOfficeManager && <AcRoleSwitcher />}
      </AcColumn>
      <AcDivider light />
      {pageLinks.map(page => (
        <MenuItem
          key={page.id}
          onClick={() => {
            page.onClick()
            handleClose()
          }}>
          <ListItemIcon>{page.icon}</ListItemIcon>
          <ListItemText sx={{ color: ICON_COLOR }}>{page.label}</ListItemText>
        </MenuItem>
      ))}
      <AcDivider light />
      {externalLinks.map(page => (
        <a
          style={{ color: 'inherit' }}
          href={page.href}
          target="_blank"
          rel="noopener noreferrer">
          <MenuItem
            key={page.id}
            onClick={handleClose}
            sx={{
              '&:hover': {
                '#launch': {
                  color: `${ICON_COLOR} !important`,
                },
              },
            }}>
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText sx={{ color: ICON_COLOR }}>{page.label}</ListItemText>
            <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LaunchIcon
                id="launch"
                fontSize={ICON_SIZE}
                sx={{ color: EXT_ICON_COLOR }}
              />
            </ListItemIcon>
          </MenuItem>
        </a>
      ))}
    </>
  )
}
