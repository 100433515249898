import { observer } from 'mobx-react-lite'

import { AcContainer, AcTabs, AcTypography } from '@components'
import { useStore } from '@hooks'
import {
  AcExcludedProductsTab,
  AcLunchProfileTab,
  AcUserManagementTab,
} from '@partials'

export const CustomerProfileView = observer(() => {
  const { user } = useStore()
  const tabs = [
    {
      title: 'Lunchprofiel',
      content: <AcLunchProfileTab />,
    },
    {
      title: 'Uitgesloten producten',
      content: <AcExcludedProductsTab />,
    },
    {
      title: 'Gebruikers',
      content: <AcUserManagementTab />,
    },
  ]

  return (
    <AcContainer>
      <AcTypography component="h1">{user.customer?.name}</AcTypography>
      <AcTabs
        tabs={tabs}
        small
      />
    </AcContainer>
  )
})
