import { useMemo } from 'react'
import type { IAcChartData, IAcChartDataProps } from '@typings'

import { AcChartBarDouble, AcChartBarSingle } from '@components'

export interface IAcCharts {
  chartData: IAcChartDataProps
}

export const AcCharts = ({ chartData }: IAcCharts) => {
  const data = useMemo(() => {
    const newChartData = chartData as unknown as IAcChartData
    newChartData.datasets = chartData.datasets.map(d => ({
      ...d,
      datasetName: d.name,
    }))
    newChartData.categories = chartData.categories.map(c => c.label)
    return newChartData
  }, [chartData])

  if (chartData.type === 'double') return <AcChartBarDouble {...data} />

  return <AcChartBarSingle {...data} />
}
