import { useMemo } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import { FONT_WEIGHTS } from 'src/theme/typography.theme'

import { TYPOGRAPHY_SPACING } from '@constants'
import { useBreakpoints } from '@hooks'

interface IAcTypography extends TypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'p' | 'span'
  variant?: 'h1' | 'h2' | 'h3' | 'p' | 'span'
  size?: 'xs' | 'sm' | 'md' | 'mdlg' | 'lg' | 'xl'
  padding?: 'sm' | 'md' | 'lg'
  color?: TypographyProps['color']
  removePadding?: boolean
  bold?: boolean
  children: React.ReactNode | string
}

export const AcTypography = ({
  component = 'p',
  size,
  padding = 'md',
  bold = false,
  removePadding = false,
  color = 'black',
  variant,
  children,
  ...props
}: IAcTypography) => {
  const { currentViewport } = useBreakpoints()

  const headings = ['h1', 'h2', 'h3']

  const fontSize = useMemo(() => {
    if (!size) return undefined
    switch (size) {
      case 'xs':
        return '0.75rem'
      case 'sm':
        return '0.875rem'

      case 'mdlg':
        return '1.125rem'

      case 'lg':
        return '1.25rem'

      case 'xl':
        return '1.625rem'

      case 'md':

      default:
        return '1rem'
    }
  }, [size])

  const getVariant = useMemo(() => {
    if (variant) return variant
    return component || 'p'
  }, [variant, component])

  const fontWeight = useMemo(() => {
    if (bold || headings.includes(component)) return FONT_WEIGHTS.BOLD
    return FONT_WEIGHTS.REGULAR
  }, [component, bold])

  const spacing = useMemo(() => {
    return TYPOGRAPHY_SPACING[currentViewport]
  }, [padding, currentViewport])

  const noPadding = removePadding || component === 'span'

  return (
    <Typography
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      pb={noPadding ? 0 : spacing[padding]}
      component={component}
      variant={getVariant}
      display="block"
      {...props}>
      {children}
    </Typography>
  )
}
