import { useEffect, useMemo, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import SettingsIcon from '@mui/icons-material/Settings'
import { Grid, Paper } from '@mui/material'
import { observer } from 'mobx-react-lite'

import extrasImage from '@assets/visuals/extras_image.jpg'
import {
  AcAlert,
  AcBox,
  AcButton,
  AcCard,
  AcCharts,
  AcCheckboxGroup,
  AcColumn,
  AcContainer,
  AcDivider,
  AcEmailInput,
  AcExtrasCard,
  AcGridContainer,
  AcGridItem,
  AcNumberInput,
  AcPasswordInput,
  AcRadioGroup,
  AcRow,
  AcSection,
  AcSelect,
  AcTabs,
  AcTag,
  AcTextarea,
  AcTextInput,
  AcTypography,
} from '@components'
import { useStore } from '@hooks'
import { useBreakpoints } from '@hooks'
import {
  AcCancelLunchForm,
  AcChangePasswordForm,
  AcLunchReminderForm,
} from '@partials'

export const ComponentsView = observer(() => {
  const { toaster, dialog, orders } = useStore()
  const { currentViewport } = useBreakpoints()

  const [singleChart, setSingleChart] = useState<any>(null)
  const [doubleChart, setDoubleChart] = useState<any>(null)

  const blocks = useMemo(() => {
    const items = Array.from(Array(6))
    return items.map((item: any, i: number) => (
      <Grid
        sx={{
          height: 24,
          textAlign: 'center',
        }}
        tablet={2}>
        <Paper variant="outlined">Item {i + 1}</Paper>
      </Grid>
    ))
  }, [])

  const radioOptions = [
    {
      id: 1,
      value: 'First item',
      label: 'Item 1',
    },
    {
      id: 2,
      value: 'Second item',
      label: 'Item 2',
    },
    {
      id: 3,
      value: 'Item 3',
      title: 'Title here',
      label: 'Item with title',
    },
  ]

  const tabs = useMemo(() => {
    return [
      {
        title: 'Tab 1',
        content: <div>Hello tab 1</div>,
      },
      {
        title: 'Tab 2',
        content: <div>Hello tab 2</div>,
      },
      {
        title: 'Tab 3',
        content: <div>Hello tab 3</div>,
      },
    ]
  }, [])

  return (
    <AcContainer>
      <AcDivider />
      <AcSection>
        <AcTypography component="span">
          Section (check padding top / bottom)
        </AcTypography>
      </AcSection>
      <AcDivider />
      <AcSection>
        {singleChart && <AcCharts chartData={singleChart} />}
      </AcSection>
      <AcDivider />
      <AcSection>
        {doubleChart && <AcCharts chartData={doubleChart} />}
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Heading 1</AcTypography>
        <AcTypography component="h2">Heading 2</AcTypography>
        <AcTypography component="h3">Heading 3</AcTypography>
        <AcTypography
          size="xs"
          component="span">
          Span size xs
        </AcTypography>
        <AcTypography
          size="sm"
          component="span">
          Span size sm
        </AcTypography>
        <AcTypography
          size="md"
          component="span">
          Span size md
        </AcTypography>
        <AcTypography
          size="lg"
          component="span">
          Span size lg
        </AcTypography>
        <AcTypography
          size="xs"
          component="p">
          Paragraph size xs
        </AcTypography>
        <AcTypography
          size="sm"
          component="p">
          Paragraph size sm
        </AcTypography>
        <AcTypography
          size="md"
          component="p">
          Paragraph size md
        </AcTypography>
        <AcTypography
          size="lg"
          component="p">
          Paragraph size lg
        </AcTypography>
        <AcTypography
          size="xs"
          component="p"
          bold>
          Paragraph size xs bold
        </AcTypography>
        <AcTypography
          size="sm"
          component="p"
          bold>
          Paragraph size sm bold
        </AcTypography>
        <AcTypography
          size="md"
          bold>
          Paragraph size md bold
        </AcTypography>
        <AcTypography
          size="lg"
          bold>
          Paragraph size lg bold
        </AcTypography>
        <AcTypography padding="sm">Paragraph SM padding</AcTypography>
        <AcTypography>Paragraph normal padding</AcTypography>
        <AcTypography padding="lg">Paragraph LG padding</AcTypography>
        <AcTypography removePadding>Paragraph no padding</AcTypography>
        <AcDivider />
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Buttons</AcTypography>
        <AcBox
          display="flex"
          gap={2}
          flexDirection="column">
          <AcBox
            display="flex"
            gap={2}
            flexWrap="wrap">
            <AcButton variant="primary">Primary</AcButton>
            <AcButton endIcon={<ArrowForwardIcon />}>EndIcon</AcButton>
            <AcButton startIcon={<ArrowBackIcon />}>StartIcon</AcButton>
            <AcButton
              startIcon={<FavoriteBorderIcon />}
              endIcon={<ArrowForwardIcon />}>
              Both Icons
            </AcButton>
            <AcButton
              disabled
              variant="primary">
              Primary disabled
            </AcButton>
          </AcBox>

          <AcBox
            display="flex"
            gap={2}
            flexWrap="wrap">
            <AcButton variant="secondary">Secondary</AcButton>
            <AcButton
              variant="secondary"
              endIcon={<ArrowForwardIcon />}>
              EndIcon
            </AcButton>
            <AcButton
              variant="secondary"
              startIcon={<ArrowBackIcon />}>
              StartIcon
            </AcButton>
            <AcButton
              variant="secondary"
              startIcon={<FavoriteBorderIcon />}
              endIcon={<ArrowForwardIcon />}>
              Both Icons
            </AcButton>
            <AcButton
              disabled
              variant="secondary">
              Secondary disabled
            </AcButton>
          </AcBox>

          <AcBox
            display="flex"
            gap={2}
            flexWrap="wrap">
            <AcButton variant="text">Text</AcButton>
            <AcButton
              variant="text"
              endIcon={<ArrowForwardIcon />}>
              EndIcon
            </AcButton>
            <AcButton
              variant="text"
              startIcon={<ArrowBackIcon />}>
              StartIcon
            </AcButton>
            <AcButton
              variant="text"
              startIcon={<FavoriteBorderIcon />}
              endIcon={<ArrowForwardIcon />}>
              Both Icons
            </AcButton>
            <AcButton
              disabled
              variant="text">
              Text disabled
            </AcButton>
          </AcBox>
          <AcCard cardColor="green">
            <AcBox
              display="flex"
              gap={2}
              flexWrap="wrap">
              <AcButton variant="white">White</AcButton>
              <AcButton
                variant="white"
                endIcon={<ArrowForwardIcon />}>
                EndIcon
              </AcButton>
              <AcButton
                variant="white"
                startIcon={<ArrowBackIcon />}>
                StartIcon
              </AcButton>
              <AcButton
                variant="white"
                startIcon={<FavoriteBorderIcon />}
                endIcon={<ArrowForwardIcon />}>
                Both Icons
              </AcButton>
              <AcButton
                disabled
                variant="white">
                White disabled
              </AcButton>
            </AcBox>
          </AcCard>
          <AcBox
            display="flex"
            gap={2}
            flexWrap="wrap">
            <AcButton size="small">Sm Button</AcButton>
            <AcButton>Md Button</AcButton>
            <AcButton size="large">Lg Button</AcButton>
          </AcBox>
        </AcBox>
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Spacing</AcTypography>
        <AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 1</AcTypography>
            <Grid
              container
              gap={1}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 2</AcTypography>
            <Grid
              container
              gap={2}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 3</AcTypography>
            <Grid
              container
              gap={3}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 4</AcTypography>
            <Grid
              container
              gap={4}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 5</AcTypography>

            <Grid
              container
              gap={5}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 6</AcTypography>
            <Grid
              container
              gap={6}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 7</AcTypography>
            <Grid
              container
              gap={7}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 8</AcTypography>
            <Grid
              container
              gap={8}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 9</AcTypography>
            <Grid
              container
              gap={9}>
              {blocks}
            </Grid>
          </AcBox>
          <AcBox
            mt={2}
            mb={2}>
            <AcTypography>Spacing 10</AcTypography>
            <Grid
              container
              gap={10}>
              {blocks}
            </Grid>
          </AcBox>
        </AcBox>
      </AcSection>
      <AcDivider />

      <AcSection>
        <AcTypography component="h1">Grid gap - {currentViewport}</AcTypography>
        <AcTypography size="sm">Resize window to view breakpoints</AcTypography>
        <AcSection>
          <AcTypography component="h2">Gap SM - {currentViewport}</AcTypography>
          <AcGridContainer spacing="sm">
            <AcGridItem mobile={3}>
              <AcCard>Item 1</AcCard>
            </AcGridItem>
            <AcGridItem mobile={3}>
              <AcCard>Item 2</AcCard>
            </AcGridItem>
            <AcGridItem mobile={3}>
              <AcCard>Item 3</AcCard>
            </AcGridItem>
          </AcGridContainer>
        </AcSection>
        <AcSection>
          <AcTypography component="h2">Gap MD - {currentViewport}</AcTypography>
          <AcGridContainer spacing="md">
            <AcGridItem mobile={3}>
              <AcCard>Item 1</AcCard>
            </AcGridItem>
            <AcGridItem mobile={3}>
              <AcCard>Item 2</AcCard>
            </AcGridItem>
            <AcGridItem mobile={3}>
              <AcCard>Item 3</AcCard>
            </AcGridItem>
          </AcGridContainer>
        </AcSection>
        <AcTypography component="h2">Gap LG - {currentViewport}</AcTypography>
        <AcGridContainer spacing="lg">
          <AcGridItem mobile={3}>
            <AcCard>Item 1</AcCard>
          </AcGridItem>
          <AcGridItem mobile={3}>
            <AcCard>Item 2</AcCard>
          </AcGridItem>
          <AcGridItem mobile={3}>
            <AcCard>Item 3</AcCard>
          </AcGridItem>
        </AcGridContainer>
      </AcSection>
      <AcDivider />

      <AcSection>
        <AcTypography component="h1">Cards</AcTypography>
        <AcColumn>
          <AcCard>
            <AcTypography component="h2">Card with md padding</AcTypography>
          </AcCard>
          <AcCard padding="lg">
            <AcTypography component="h2">Card with lg padding</AcTypography>
          </AcCard>
          <AcCard cardColor="green">
            <AcTypography component="h2">Green card</AcTypography>
          </AcCard>
          <AcCard cardColor="yellow">
            <AcTypography component="h2">Yellow card</AcTypography>
          </AcCard>
          <AcCard cardColor="pink">
            <AcTypography component="h2">Pink card</AcTypography>
          </AcCard>
        </AcColumn>
      </AcSection>
      <AcSection>
        <AcGridContainer>
          <AcGridItem mobile={5}>
            <AcCard>
              <AcTypography component="h2">Card with md padding</AcTypography>
            </AcCard>
          </AcGridItem>
          <AcGridItem mobile={5}>
            <AcCard padding="lg">
              <AcTypography component="h2">Card with lg padding</AcTypography>
            </AcCard>
          </AcGridItem>
          <AcGridItem mobile={5}>
            <AcCard>
              <AcTypography component="h2">Card with md padding</AcTypography>
            </AcCard>
          </AcGridItem>
          <AcGridItem mobile={5}>
            <AcCard>
              <AcTypography component="h2">Card with md padding</AcTypography>
            </AcCard>
          </AcGridItem>
          <AcGridItem mobile={5}>
            <AcCard cardColor="green">
              <AcTypography component="h2">Green card</AcTypography>
            </AcCard>
          </AcGridItem>
          <AcGridItem mobile={5}>
            <AcCard cardColor="yellow">
              <AcTypography component="h2">Yellow card</AcTypography>
            </AcCard>
          </AcGridItem>
        </AcGridContainer>
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Alerts</AcTypography>
        <AcColumn spacing="md">
          <AcAlert severity="success">Alert Success</AcAlert>
          <AcAlert
            title="With title"
            severity="success">
            This is a success Alert with an encouraging title.
          </AcAlert>
          <AcAlert severity="warning">Alerto Warning</AcAlert>
          <AcAlert
            title="Warning with title"
            severity="warning">
            Alerto Warning
          </AcAlert>
          <AcAlert
            title="Warning with Errrror"
            severity="error">
            Alerto Error
          </AcAlert>
        </AcColumn>
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Tags</AcTypography>
        <AcBox
          display="flex"
          flexWrap="wrap"
          gap={3}>
          <AcTag label="I am default" />
          <AcTag
            color="green"
            label="I am green"
          />
          <AcTag
            color="pink"
            label="I am pink"
          />
          <AcTag
            color="yellow"
            label="I am yellow"
          />
          <AcTag
            color="salmon"
            label="I am salmon"
          />
        </AcBox>
      </AcSection>
      <AcSection>
        <AcTypography component="h1">Tabs</AcTypography>
        <AcTabs tabs={tabs} />
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h1">Forms</AcTypography>
        <AcSection>
          <AcColumn>
            <AcTypography component="h2">Contained Radio</AcTypography>
            <AcRadioGroup
              id="test"
              options={radioOptions}
              label="Options label here"
            />
            <AcRadioGroup
              id="test-2"
              options={radioOptions}
              label="Options label here"
              variant="default"
            />
          </AcColumn>
        </AcSection>
        <AcSection>
          <AcTypography component="h2">Contained Checkbox</AcTypography>
          <AcColumn>
            <AcCheckboxGroup
              id="test"
              options={radioOptions}
              label="Contained Checkbox"
            />
            <AcCheckboxGroup
              id="test-2"
              options={radioOptions}
              label="Default Checkbox"
              variant="default"
            />
          </AcColumn>
        </AcSection>
        <AcSection>
          <AcTypography component="h2">Form inputs</AcTypography>
          <AcRow flexWrap="wrap">
            <AcTextInput label="Text input (label)" />
            <AcTextInput
              label="Input + placeholder"
              placeholder="With placeholder..."
            />
            <AcTextInput
              label="Text input disabled"
              disabled
            />
            <AcTextInput
              label="Text input"
              helperText="I am the helper text"
            />
            <AcTextInput
              label="Text input (label)"
              error="I am the error text"
            />
          </AcRow>
          <AcRow>
            <AcEmailInput label="Email input" />
            <AcEmailInput
              label="Email input"
              placeholder="Type your email.."
            />
          </AcRow>
          <AcRow>
            <AcTextarea
              label="I am a text area"
              placeholder="You can type loads of things over here.."
            />
          </AcRow>
          <AcRow>
            <AcPasswordInput label="Password" />
            <AcPasswordInput
              label="Password + visible"
              allowVisible
            />
          </AcRow>
          <AcTypography component="h2">Number inputs</AcTypography>
          <AcRow flexWrap="wrap">
            <AcNumberInput label="Number input" />
            <AcNumberInput
              label="# input min 3"
              min={3}
            />
            <AcNumberInput
              label="# input max 10"
              max={10}
            />
            <AcNumberInput
              label="# input step 0.5"
              step={0.5}
              onChange={e => console.log(e)}
            />
          </AcRow>
          <AcCard cardColor="pink">
            <AcRow
              flexWrap="wrap"
              p={2}>
              <AcNumberInput variant="styled" />
              <AcNumberInput
                variant="styled"
                min={3}
                onChange={e => console.log(e)}
              />
              <AcNumberInput
                variant="styled"
                max={10}
                onChange={e => console.log(e)}
              />
              <AcNumberInput
                variant="styled"
                step={0.5}
                onChange={e => console.log(e.target.value)}
              />
            </AcRow>
          </AcCard>
        </AcSection>
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography component="h2">Select</AcTypography>
        <AcSelect
          id="select"
          label="Default dropdown"
          options={radioOptions}
          value={''}
          onChange={(e: any) => console.log(e)}
        />
        <AcSelect
          id="select"
          label="Dropdown with icon"
          options={radioOptions}
          value={''}
          onChange={(e: any) => console.log(e)}
          startIcon={<SettingsIcon />}
        />
        <AcSelect
          id="select"
          label="Disabled dropdown"
          options={radioOptions}
          value={''}
          onChange={(e: any) => console.log(e)}
          disabled
        />
        <AcSelect
          id="select"
          label="Helpful dropdown"
          options={radioOptions}
          value={''}
          onChange={(e: any) => console.log(e)}
          helperText="This dropdown has some helpful helpertext"
        />
        <AcSelect
          id="select"
          label="Error dropdown"
          options={radioOptions}
          value={''}
          onChange={(e: any) => console.log(e)}
          error="This dropdown has an error"
        />{' '}
        <AcSelect
          id="select"
          label="Dropdown multiple options"
          options={radioOptions}
          value={[]}
          allowMultiple
          onChange={(e: any) => console.log(e)}
        />
      </AcSection>
      <AcDivider />
      <AcSection>
        <AcTypography>Snackbars / Toasters</AcTypography>
        <AcRow flexWrap="wrap">
          <AcButton
            onClick={() => toaster.success({ content: 'I am successful' })}>
            I am successful
          </AcButton>
          <AcButton
            onClick={() => toaster.warning({ content: 'I am a warning' })}>
            I am a warning
          </AcButton>
          <AcButton
            onClick={() => toaster.error({ content: 'I am an error msg' })}>
            I am a big fat error
          </AcButton>
          <AcButton
            onClick={() =>
              toaster.success({
                title: 'I am successful',
                content: 'Like, really successful',
              })
            }>
            And I have a title
          </AcButton>
          <AcButton
            onClick={() =>
              toaster.success({
                content: 'I will close a bit quicker',
                timeout: 2000,
              })
            }>
            Close in 2s
          </AcButton>
        </AcRow>
      </AcSection>
      <AcSection>
        <AcTypography component="h2">Dialog</AcTypography>
        <AcRow>
          <AcButton
            onClick={() =>
              dialog.open({
                title: 'Deze week geen lunch',
                subTitle: 'Week 25 - 17 juni t/m 21 juni',
                content: <AcCancelLunchForm />,
              })
            }>
            Stop lunch
          </AcButton>
          <AcButton
            onClick={() =>
              dialog.open({
                title: 'Herinnering sturen',
                subTitle:
                  "Stuur een bericht om collega's te herinneren zich aan te melden voor de lunch.",
                content: <AcLunchReminderForm />,
              })
            }>
            Herinnering sturen
          </AcButton>
          <AcButton
            onClick={() =>
              dialog.open({
                title: 'Selecteer je voorkeur',
                subTitle: 'Stel een nieuw wachtwoord in.',
                content: <AcChangePasswordForm />,
              })
            }>
            Kies opties
          </AcButton>
        </AcRow>
      </AcSection>
    </AcContainer>
  )
})
