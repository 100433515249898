import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ArrowForward } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'

import { AcTable, AcTag, AcTypography } from '@components'
import { KEYS, LABELS } from '@constants'
import { useDateHelpers } from '@hooks'
import { IOrderSummary } from '@typings'

export const AcOrdersTableRow = (order: IOrderSummary) => {
  const location = useLocation()
  const { weekStartDate, weekEndDateFriday } = useDateHelpers(
    order.start_of_week,
    { dateOptions: { day: '2-digit', month: 'long' } },
  )

  const tagColor = useMemo(() => {
    switch (order.status) {
      case KEYS.STATUS_CONFIRMED:
      case KEYS.STATUS_FULFILLED:
      default:
        return 'green'

      case KEYS.STATUS_PENDING:
        return 'salmon'

      case KEYS.STATUS_CANCELLED:
        return 'pink'
    }
  }, [order.status])

  const renderIcon = useMemo(() => {
    switch (order.status) {
      case KEYS.STATUS_CONFIRMED:
      case KEYS.STATUS_FULFILLED:
      default:
        return <CheckCircleIcon sx={{ color: 'green.main' }} />

      case KEYS.STATUS_PENDING:
      case KEYS.STATUS_CANCELLED:
        return <IndeterminateCheckBoxIcon sx={{ color: 'pink.main' }} />
    }
  }, [order.status])

  const renderTitle = useMemo(() => {
    return `Week ${order.week} - ${weekStartDate} t/m ${weekEndDateFriday}`
  }, [order.week])

  const renderHref = useMemo(() => {
    if (order.status === KEYS.STATUS_PENDING) return undefined
    return `${location.pathname}/${order.id}`
  }, [order.status, order.id])

  return (
    <AcTable.Row
      component={Link}
      to={renderHref}
      sx={{
        '&a': {
          cursor: 'pointer',
        },
      }}>
      <AcTable.Cell>{renderIcon}</AcTable.Cell>
      <AcTable.Cell>
        <AcTypography
          component="span"
          bold
          color="green.main">
          {renderTitle}
        </AcTypography>
      </AcTable.Cell>
      <AcTable.Cell>
        <AcTag
          color={tagColor}
          label={LABELS[order?.status?.toUpperCase() as keyof typeof LABELS]}
        />
      </AcTable.Cell>
      <AcTable.Cell align="right">
        <ArrowForward sx={{ color: 'green.main' }} />
      </AcTable.Cell>
    </AcTable.Row>
  )
}
