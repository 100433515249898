import { AcAnimatedFade, AcAnimatedSpring } from '@animations'

import {
  AcBox,
  AcColumn,
  AcOnboardingSuccessSVG,
  AcPWAPrompt,
  AcTypography,
} from '@components'
import { useStore } from '@hooks'

export const OnboardingCompletedView = () => {
  const { user } = useStore()

  return (
    <AcBox>
      <AcColumn
        height="40vh"
        justifyContent="start"
        alignItems="center">
        <AcOnboardingSuccessSVG />
        <AcAnimatedFade delay={1}>
          <AcTypography
            component="h2"
            color="white.main">
            Welkom bij de Buurtboer {user.current?.first_name}!
          </AcTypography>
        </AcAnimatedFade>
      </AcColumn>
      <AcBox
        position="fixed"
        bottom={8}
        left={8}
        right={8}
        display="flex"
        justifyContent="center">
        <AcAnimatedSpring delay={1.5}>
          <AcPWAPrompt />
        </AcAnimatedSpring>
      </AcBox>
    </AcBox>
  )
}
