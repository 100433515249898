import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'

import { LABELS, STORE_KEYS } from '@constants'
import { extractAPIFieldErrors, getFromStorage, saveToStorage } from '@helpers'
import { Store } from '@stores'
import {
  BeforeInstallPromptEvent,
  IAllergy,
  ICustomer,
  IErrorResponse,
  IPreferenceEnum,
  IProfileResponse,
  IRole,
  IRoleEnum,
  IUser,
  IWeekDay,
} from '@typings'

export interface IUserStore {
  user: IUser
  activeRole: IRoleEnum
}

export class UserStore {
  store: Store
  loading: boolean
  pwa_event: BeforeInstallPromptEvent | null
  user: IUserStore['user'] | null
  activeRole: IRoleEnum

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.user = null
    this.loading = false
    this.pwa_event = null
    this.activeRole = (getFromStorage('activeRole') as IRoleEnum) || 'LUNCHER'
  }

  @computed
  get watchUserRoles(): IRole[] | undefined {
    return this.user?.roles
  }

  @computed
  get isLoading(): boolean {
    return this.loading
  }

  @computed
  get watchActiveRole(): IRoleEnum {
    return this.activeRole
  }

  @computed
  get watchPWAEvent(): BeforeInstallPromptEvent | null {
    return this.pwa_event
  }

  @computed
  get current(): IUser | null {
    return this.user
  }

  @computed
  get customer(): ICustomer | undefined {
    return this.user?.customer
  }

  @computed
  get hasSoup() {
    return !!this.user?.customer?.has_soup
  }

  @computed
  get hasSalad() {
    return !!this.user?.customer?.has_salad
  }

  @action
  saveDeferredPWAEvent = (event: BeforeInstallPromptEvent) => {
    this.store.set(STORE_KEYS.USER, 'pwa_event', event)
  }

  get isOfficeManager(): boolean {
    return (
      this.user?.roles?.some(role => role.name === 'office-manager') || false
    )
  }

  @action
  attemptPWAInstall = async () => {
    if (this.pwa_event) {
      this.pwa_event.prompt()
      const { outcome } = await this.pwa_event.userChoice
      // The deferredPrompt can only be used once.
      this.store.set(STORE_KEYS.USER, 'pwa_event', null)
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.')
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt')
      }
    }
  }

  @action
  whoAmI = async () => {
    try {
      this.store.set(STORE_KEYS.USER, 'loading', true)
      const data = await this.store.api.user.whoAmI()
      this.setUser(data)
    } catch (e) {
      this.store.auth.postLogout()
      const err: IErrorResponse = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  setUser = (user: IProfileResponse) => {
    this.store.set(STORE_KEYS.USER, 'user', user)
  }

  @action
  setRole = (role: IRoleEnum) => {
    // Don't show toaster on initial page load
    if (this.store.user.activeRole !== role) {
      this.store.toaster.warning({
        content: `Je gebruikt de app nu als ${LABELS[role]}`,
      })
    }
    this.store.set(STORE_KEYS.USER, 'activeRole', role)
    saveToStorage('activeRole', role)
  }

  @action
  patchAllergies = async (allergies: IAllergy['id'][]) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchAllergies(allergies)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  patchDefaultAttendance = async (days: any) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchDefaultAttendance(days)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  patchPreference = async (preference: IPreferenceEnum) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchPreference(preference)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  updateWeekAttendance = async (weekIdentifier: any, days: IWeekDay[]) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      await this.store.api.user.postWeeklyAttendance({
        weekIdentifier,
        days,
      })
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  reset = () => {
    this.user = null
    this.loading = false
    this.pwa_event = null
    this.activeRole = 'LUNCHER'
  }
}
