import { IUser } from '@typings'

const USERS = '/users'
const USER = '/user'
const PROFILE = '/profile'
const AUTH = '/auth'
const WEEKS = '/weeks'
const PRODUCT = '/product'
const SPECIFICATIONS = '/specifications'
const LUNCHER = '/luncher'
const PREFERENCES = '/preferences'

export const ENDPOINTS = {
  USERS: {
    SINGLE: (ID: IUser['id']) => `${USERS}/${ID}`,
  },

  USER: {
    WHO_AM_I: PROFILE,
    CHANGE_PASSWORD: `${PROFILE}/change-password`,
    ROLES: `${USER}/roles`,
    ALLERGIES: `${USER}/allergies`,
    PREFERENCE: `${USER}/preference`,
    DEFAULT_ATTENDANCE: `${USER}/default-attendance`,
    PROFILE: PROFILE,
  },

  AUTHENTICATION: {
    LOGIN: `${AUTH}/login`,
    REGISTER: `${AUTH}/register`,
    INVITE: `${AUTH}/invite`,
    REQUEST_NEW_PASSWORD: `${AUTH}/forgot-password`,
    VALIDATE_RESET: `${AUTH}/validate-reset`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
  },

  LUNCHER: {
    WEEKS: `${LUNCHER}${WEEKS}`,
  },

  WEEKS: {
    SINGLE: (week: string) => `${WEEKS}/${week}`,
    SINGLE_LUNCHERS: (week: string) => `${WEEKS}/${week}/lunchers`,
  },
  PRODUCTS: {
    EXTRAS: (week: string) => `${PRODUCT}/extras?week=${week}`,
    ALLERGIES: `${PRODUCT}/allergies`,
    RATE: (id: number, score: number) => `/product/${id}/rating?score=${score}`,
    PREFERENCES: `${PRODUCT}${PREFERENCES}`,
  },

  SPECIFICATIONS: {
    INDEX: SPECIFICATIONS,
    SINGLE: (id: number) => `${SPECIFICATIONS}/${id}`,
  },
}
