import ErrorIcon from '@mui/icons-material/Error'
import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material'

import { AcColumn, AcHelpertext } from '@components'

type Omitted = 'helperText' | 'error'

export interface IAcTextInput extends Omit<StandardTextFieldProps, Omitted> {
  helperText?: string
  error?: string
}

export const AcTextInput = ({ helperText, error, ...props }: IAcTextInput) => {
  // Otherwise the floating label is not working
  if (!props.value) {
    props.value = ''
  }
  return (
    <AcColumn gap={1}>
      <TextField
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment: error ? (
            <InputAdornment position="end">
              <ErrorIcon color="error" />
            </InputAdornment>
          ) : (
            props.InputProps?.endAdornment
          ),
        }}
        sx={{
          ...props.sx,
          '.MuiOutlinedInput-root': {
            outlineColor: error && 'error.main',
            outlineWidth: error && 2,
          },
        }}
      />
      {helperText && <AcHelpertext text={helperText} />}
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </AcColumn>
  )
}
