import { useTheme } from '@mui/material'
import { FONT_FAMILIES } from 'src/theme/typography.theme'

export const useChartStyling = () => {
  const theme = useTheme()

  const barStyles = {
    normal: {
      borderRadius: 8,
      borderColor: (params: any) => params.color,
      borderWidth: 2,
    },
  }

  const legendStyles = {
    type: 'plain',
    orient: 'horizontal',
    left: 'left',
    bottom: 0,
    itemGap: 32,
    textStyle: {
      fontFamily: FONT_FAMILIES.POSTERAMA_SHORT,
    },
    icon: 'circle',
  }

  const tooltipStyles = {
    padding: 10,
    textStyle: {
      color: theme.palette.green.main,
      fontSize: theme.typography.fontSize,
      fontFamily: FONT_FAMILIES.POSTERAMA_SHORT,
    },
  }

  const topLabelStyles = {
    show: true,
    position: 'top',
    fontFamily: FONT_FAMILIES.ROCA_SHORT,
    fontSize: theme.typography.fontSize,
    color: theme.palette.green.main,
  }

  const XaxisLabelStyles = {
    fontFamily: FONT_FAMILIES.POSTERAMA_SHORT,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.black.main,
  }

  const YaxisLabelStyles = {
    fontFamily: FONT_FAMILIES.POSTERAMA_SHORT,
  }

  return {
    barStyles,
    legendStyles,
    tooltipStyles,
    topLabelStyles,
    XaxisLabelStyles,
    YaxisLabelStyles,
  }
}
