import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IAllergiesResponse,
  IExtrasResponse,
  IPreferencesResponse,
  IProductRatingResponse,
} from '@typings'

export class ProductsApi extends AxiosBaseApi {
  extras = (week: string) =>
    this.api
      .get<{
        data: IExtrasResponse
      }>(ENDPOINTS.PRODUCTS.EXTRAS(week))
      .then(response => response.data)

  allergies = () =>
    this.api
      .get<{
        data: IAllergiesResponse
      }>(ENDPOINTS.PRODUCTS.ALLERGIES)
      .then(response => response.data)

  preferences = () =>
    this.api
      .get<{
        data: IPreferencesResponse
      }>(ENDPOINTS.PRODUCTS.PREFERENCES)
      .then(response => response.data)

  setProductRating = (id: number, score: number) =>
    this.api
      .put<{
        data: IProductRatingResponse
      }>(ENDPOINTS.PRODUCTS.RATE(id, score))
      .then(response => response.data)
}
