import { useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FONT_FAMILIES } from 'src/theme/typography.theme'

import soupSaladBG from '@assets/visuals/soup_salad_card_bg.png'
import {
  AcBox,
  AcCard,
  AcColumn,
  AcGridContainer,
  AcGridItem,
  AcIcon,
  AcRow,
  AcTag,
  AcTypography,
} from '@components'
import { ICONS, WEEKDAYS_KEYS, WEEKDAYS_LABELS_SHORT } from '@constants'
import { LABELS } from '@constants'
import { formatPrice } from '@helpers'
import { useBreakpoints, useStore } from '@hooks'
import { IWeekDay } from '@typings'

export const AcLunchProfileTab = observer(() => {
  const { user } = useStore()
  const { isMobile } = useBreakpoints()

  const hasWeekMenu = useMemo(() => {
    return user.hasSoup || user.hasSalad
  }, [user.current])

  const getWeekLunchers = useMemo(() => {
    const values = user.customer?.lunch_days.map(day => Object.values(day)[0])
    if (!values) {
      return 0
    }
    return values.reduce((acc, value) => {
      return acc + value
    })
  }, [user.current])

  const renderTableHeader = useMemo(() => {
    return (
      <AcGridContainer>
        <AcGridItem mobile={7}></AcGridItem>
        {Object.values(WEEKDAYS_LABELS_SHORT).map(label => (
          <AcGridItem
            key={label}
            mobile={1}>
            <AcTypography
              component="span"
              textAlign="center">
              {label}
            </AcTypography>
          </AcGridItem>
        ))}
      </AcGridContainer>
    )
  }, [])

  const renderDeliveryDays = useMemo(() => {
    const getDeliveryDayIcon = (day: IWeekDay) => {
      if (!user.customer?.delivery_days.includes(day)) {
        return <CloseIcon sx={{ color: 'light-green.main' }} />
      }
      return <AcIcon icon={ICONS.DELIVERY_TRUCK} />
    }

    return (
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={7}>
          <AcTypography
            component="span"
            bold>
            {LABELS.DELIVERY_DAYS}
          </AcTypography>
        </AcGridItem>
        {Object.values(WEEKDAYS_KEYS).map(label => (
          <AcGridItem
            mobile={2}
            tablet={1}
            key={label}>
            <AcColumn alignItems="center">
              {isMobile && (
                <span>
                  {
                    WEEKDAYS_LABELS_SHORT[
                      label.toUpperCase() as keyof typeof WEEKDAYS_LABELS_SHORT
                    ]
                  }
                </span>
              )}
              <span>{getDeliveryDayIcon(label as IWeekDay)}</span>
            </AcColumn>
          </AcGridItem>
        ))}
      </AcGridContainer>
    )
  }, [user.customer, isMobile])

  const renderLunchDays = useMemo(() => {
    const getLunchDayIcon = (day: IWeekDay) => {
      if (
        !user.customer?.lunch_days.some(lunchDay =>
          lunchDay.hasOwnProperty(day),
        )
      ) {
        return <CloseIcon sx={{ color: 'light-green.main' }} />
      }
      return <AcIcon icon={ICONS.KNIFE_FORK} />
    }

    return (
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={7}>
          <AcTypography
            component="span"
            bold>
            {LABELS.LUNCH_DAYS}
          </AcTypography>
        </AcGridItem>
        {Object.values(WEEKDAYS_KEYS).map(label => (
          <AcGridItem
            key={label}
            mobile={2}
            tablet={1}>
            <AcColumn alignItems="center">
              {isMobile && (
                <span>
                  {
                    WEEKDAYS_LABELS_SHORT[
                      label.toUpperCase() as keyof typeof WEEKDAYS_LABELS_SHORT
                    ]
                  }
                </span>
              )}
              <span>{getLunchDayIcon(label as IWeekDay)}</span>
            </AcColumn>
          </AcGridItem>
        ))}
      </AcGridContainer>
    )
  }, [user.customer, isMobile])

  const renderWeekMenuCard = useMemo(() => {
    if (!hasWeekMenu) return null
    return (
      <AcGridItem
        mobile={12}
        tablet={6}>
        <AcCard
          cardBackgroundImage={soupSaladBG}
          cardColor="pink">
          <AcColumn
            gap={2}
            minHeight={146}>
            <AcTypography
              removePadding
              component="h2">
              {LABELS.WEEK_MENU}
            </AcTypography>
            <AcRow gap={2}>
              {user.hasSoup && (
                <AcTag
                  large
                  color="white"
                  label="Salade"
                />
              )}
              {user.hasSalad && (
                <AcTag
                  large
                  color="white"
                  label="Soep"
                />
              )}
            </AcRow>
          </AcColumn>
        </AcCard>
      </AcGridItem>
    )
  }, [user.current])

  const renderLuncherCount = useMemo(() => {
    const countFlat = Object.values(WEEKDAYS_KEYS).map(day => {
      const item = user.customer?.lunch_days.find(lunchDay =>
        lunchDay.hasOwnProperty(day),
      )

      if (!item) return 0
      return Object.values(item)[0]
    })

    return (
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={7}>
          <AcTypography
            component="span"
            bold>
            Standaard Lunchers p.d.
          </AcTypography>
        </AcGridItem>

        {countFlat.map((count, index) => {
          return (
            <AcGridItem
              key={index}
              mobile={2}
              tablet={1}>
              <AcTypography
                component="span"
                textAlign="center"
                bold>
                {count}
              </AcTypography>
            </AcGridItem>
          )
        })}
      </AcGridContainer>
    )
  }, [user.customer])

  if (!user.current || !user.customer) {
    return null
  }

  return (
    <>
      <AcTypography
        size="sm"
        color="green.main">
        Aanpassen? Neem <a href="/">contact</a> op
      </AcTypography>
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={hasWeekMenu ? 6 : 12}>
          <AcCard cardColor="green">
            <AcColumn
              justifyContent="space-between"
              minHeight={146}>
              <AcBox>
                <AcTypography
                  component="h3"
                  padding="sm">
                  Lunchers
                </AcTypography>
                <AcTypography>
                  Aantal afgesproken lunchers per week
                </AcTypography>
              </AcBox>
              <AcRow
                justifyContent="space-between"
                alignItems="flex-end">
                <Typography
                  color="yellow.main"
                  lineHeight={0.5}
                  fontSize={64}
                  fontFamily={FONT_FAMILIES.ROCA}>
                  {getWeekLunchers}
                </Typography>
                <AcTypography
                  component="span"
                  size="xs">
                  EUR {formatPrice(user.customer?.budget)} Lunchbudget pp
                </AcTypography>
              </AcRow>
            </AcColumn>
          </AcCard>
        </AcGridItem>
        {renderWeekMenuCard}

        <AcGridItem mobile={12}>
          <AcCard contentColor="green.main">
            <AcTypography
              component="h3"
              removePadding>
              Bezorgafspraken
            </AcTypography>
            <AcColumn gap={1}>
              {!isMobile && renderTableHeader}
              {renderDeliveryDays}
              {renderLunchDays}
              {renderLuncherCount}
            </AcColumn>
          </AcCard>
        </AcGridItem>
      </AcGridContainer>
    </>
  )
})
