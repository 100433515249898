import { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { InputAdornment } from '@mui/material'

import { AcTextInput, IAcTextInput } from '@components'
import { OnChangeEvent } from '@typings'

interface IAcPasswordInput extends IAcTextInput {
  allowVisible?: boolean
  regex?: RegExp
  regexExplainer?: string
  onChange?: (e: OnChangeEvent) => void
  hideHelperText?: boolean
}

const PW_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/

export const AcPasswordInput = ({
  allowVisible = false,
  regex = PW_REGEX,
  regexExplainer = '8-16 tekens, 1 letter, 1 cijfer, 1 speciaal teken',
  onChange,
  hideHelperText = false,
  ...props
}: IAcPasswordInput) => {
  const [hide, setHide] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [input, setInput] = useState('')
  const Icon = hide ? VisibilityOffIcon : VisibilityIcon

  const handleVisibility = () => {
    if (allowVisible) {
      setHide(!hide)
    }
  }

  const handleChange = (e: OnChangeEvent) => {
    setInput(e.target.value)
    onChange && onChange(e)
  }

  useEffect(() => {
    if (input.length) {
      const valid = regex.test(input)
      setIsValid(valid)
    }
  }, [input])

  return (
    <AcTextInput
      type={hide ? 'password' : 'text'}
      placeholder=""
      onChange={handleChange}
      helperText={!isValid && !hideHelperText ? regexExplainer : ''}
      {...props}
      InputProps={{
        endAdornment: allowVisible ? (
          <InputAdornment
            position="end"
            onClick={handleVisibility}
            sx={{
              cursor: 'pointer',
            }}>
            <Icon sx={{ color: 'green.main' }} />
          </InputAdornment>
        ) : null,
      }}
    />
  )
}
