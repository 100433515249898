import { ButtonProps } from '@mui/material'

import { AcLoaderPulse } from '@components'

import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'
import { TextButton } from './TextButton'
import { WhiteButton } from './WhiteButton'

type Omitted = 'color' | 'variant'

export interface IAcButton extends Omit<ButtonProps, Omitted> {
  variant?: 'primary' | 'secondary' | 'text' | 'white'
  loading?: boolean
  children?: React.ReactNode
  target?: string
}

export const AcButton = ({
  variant = 'primary',
  loading,
  children,
  target,
  ...props
}: IAcButton) => {
  if (props.href && target) {
    ;(props as any).target = target
    ;(props as any).rel = 'noopener noreferrer'
  }
  if (variant === 'text') {
    return (
      <TextButton
        variant="text"
        color="green"
        disabled={props.disabled || loading}
        {...props}>
        {loading ? <AcLoaderPulse /> : <span>{children}</span>}
      </TextButton>
    )
  }

  if (variant === 'secondary') {
    return (
      <SecondaryButton
        variant="outlined"
        color="green"
        disabled={props.disabled || loading}
        {...props}>
        {loading ? <AcLoaderPulse /> : <span>{children}</span>}
      </SecondaryButton>
    )
  }

  if (variant === 'white') {
    return (
      <WhiteButton
        color="white"
        variant="contained"
        disabled={props.disabled || loading}
        {...props}>
        {loading ? <AcLoaderPulse /> : <span>{children}</span>}
      </WhiteButton>
    )
  }

  return (
    <PrimaryButton
      variant="contained"
      color="yellow"
      disabled={props.disabled || loading}
      {...props}>
      {loading ? <AcLoaderPulse /> : <span>{children}</span>}
    </PrimaryButton>
  )
}
