import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { NAVIGATE_TO } from '@routes'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcTextInput,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'

import { IRegisterUserPayload } from '../../../typings/auth.typings'

const FormInputs = () => {
  const { isMobile } = useBreakpoints()
  const [params] = useSearchParams()
  const { setValue } = useFormContext()

  useEffect(() => {
    const emailFromParams = params.get('email')
    const tokenFromParams = params.get('token')
    if (emailFromParams) {
      setValue('email', emailFromParams)
    }
  }, [])

  return (
    <>
      <AcColumn spacing="xl">
        <AcColumn gap={0}>
          <AcTypography
            color="green.main"
            bold>
            {LABELS.CREATE_ACCOUNT}
          </AcTypography>
          <AcFormInput
            register="name"
            Component={
              <AcTextInput
                label={LABELS.NAME}
                required
              />
            }
          />
          <AcFormInput
            register="email"
            Component={
              <AcEmailInput
                label={LABELS.EMAIL_ADDRESS}
                required
              />
            }
          />
        </AcColumn>
        <AcColumn gap={0}>
          <AcTypography
            color="green.main"
            bold>
            Stel je wachtwoord in
          </AcTypography>
          <AcFormInput
            register="password"
            Component={
              <AcPasswordInput
                label={LABELS.PASSWORD}
                required
              />
            }
          />
          <AcFormInput
            lastChild
            register="password_confirm"
            Component={
              <AcPasswordInput
                label={LABELS.CONFIRM_PASSWORD}
                required
              />
            }
          />
        </AcColumn>
      </AcColumn>

      <AcButton
        type="submit"
        fullWidth
        endIcon={<ArrowForwardIcon />}
        sx={{ marginTop: isMobile ? 'auto' : 6 }}>
        {LABELS.NEXT}
      </AcButton>
    </>
  )
}

export const AcRegistrationForm = () => {
  const navigate = useNavigate()
  const { auth, toaster } = useStore()

  const onSubmit = async (data: IRegisterUserPayload) => {
    // TODO
    try {
      await auth.register(data)
      navigate(NAVIGATE_TO.ONBOARDING_PREFERENCES)
    } catch (e) {
      toaster.error({
        content: 'Er is iets misgegaan. Probeer het opnieuw.',
      })
    }
  }

  return (
    <AcFormProvider
      onSubmit={onSubmit}
      initial={[]}
      flexGrow={1}>
      <FormInputs />
    </AcFormProvider>
  )
}
