import { useMemo } from 'react'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcOrderExtrasPerDay,
  AcRow,
  AcTable,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { formatPrice } from '@helpers'
import {
  IAcChartDataProps,
  IOrderDetails,
  IOrderDetailsOverviewSoupOrSalad,
} from '@typings'

export const AcOrderDetailsOverviewTab = (order: IOrderDetails) => {
  const renderChart = useMemo(() => {
    const data = order.overview?.lunchers
    if (!data?.length) return null
    const chart: IAcChartDataProps = {
      type: 'single',
      legend: 'Leganda',
      categories: data.map(data => ({
        label:
          WEEKDAYS_LABELS[
            data.day.toUpperCase() as keyof typeof WEEKDAYS_LABELS
          ],
        subLabel: 'blabla',
      })),
      datasets: [
        {
          name: 'Aantallen',
          data: data.map(data => data.count),
        },
      ],
    }
    return chart
  }, [order.overview?.lunchers])

  const preferenceRow = (input: string | IOrderDetailsOverviewSoupOrSalad) => {
    return (
      <AcTable.Row>
        <AcTable.Cell>
          {typeof input === 'string' ? (
            <AcTypography component="span">{input}</AcTypography>
          ) : (
            <AcColumn>
              <AcTypography
                fontWeight="bold"
                component="span">
                {input?.name}
              </AcTypography>
              <AcTypography component="span">{input?.description}</AcTypography>
            </AcColumn>
          )}
        </AcTable.Cell>
      </AcTable.Row>
    )
  }

  const renderSoupPreference = useMemo(() => {
    if (!order.overview?.soups?.length) return null
    return (
      <AcTable
        head={[LABELS.SOUP_PREFERENCE]}
        rows={order.overview.soups.map(pref => preferenceRow(pref))}
      />
    )
  }, [order.overview?.soups])

  const renderSaladsPreference = useMemo(() => {
    if (!order.overview?.salads?.length) return null
    return (
      <AcTable
        head={[LABELS.SALAD_PREFERENCE]}
        rows={order.overview.salads.map(pref => preferenceRow(pref))}
      />
    )
  }, [order.overview?.salads])

  const renderComment = useMemo(() => {
    if (!order?.overview?.comment) return null
    return (
      <AcTable
        head={[LABELS.ORDER_COMMENTS]}
        rows={[preferenceRow(order.overview.comment)]}
      />
    )
  }, [order.overview?.comment])

  const renderExtras = useMemo(() => {
    if (!order.overview?.extras?.days.length) return null
    return (
      <AcColumn>
        <AcTable
          head={[{ title: LABELS.EXTRAS, colSpan: 5 }]}
          rows={order.overview?.extras?.days.map(day => (
            <AcOrderExtrasPerDay {...day} />
          ))}
        />
        <AcCard>
          <AcRow justifyContent="space-between">
            <AcTypography
              component="span"
              fontWeight="bold"
              color="green.main">
              {LABELS.TOTAL_EXCL_TAXES}
            </AcTypography>
            <AcTypography
              component="span"
              fontWeight="bold"
              color="green.main">
              {formatPrice(order.overview.extras.total)}
            </AcTypography>
          </AcRow>
        </AcCard>
      </AcColumn>
    )
  }, [order.overview?.extras])

  return (
    <AcColumn>
      <AcCard>
        <AcRow justifyContent="space-between">
          <AcTypography>Aantallen</AcTypography>
          {order.download && (
            <AcButton
              href={order.download}
              target="_blank"
              startIcon={<FileDownloadOutlinedIcon />}
              variant="text">
              {LABELS.DOWNLOAD_LUNCHER_LIST}
            </AcButton>
          )}
        </AcRow>
        {renderChart && <AcCharts chartData={renderChart} />}
      </AcCard>
      {renderSoupPreference}
      {renderSaladsPreference}
      {renderComment}
      {renderExtras}
    </AcColumn>
  )
}
