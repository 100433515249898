import { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
} from '@components'
import { LABELS } from '@constants'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'

export const AcResetPasswordForm = observer(() => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { toaster, auth } = useStore()
  const { isMobile } = useBreakpoints()

  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const onSubmit = async (values: any) => {
    try {
      await auth.postResetPassword({ ...values, token })
      toaster.success({ content: LABELS.PASSWORD_CHANGED })
      navigate(NAVIGATE_TO.LOGIN)
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        if (!err.errorFields) {
          toaster.error({ content: err.message })
        }
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcFormProvider
      initial={[]}
      errors={errors}
      onSubmit={onSubmit}
      flexGrow={1}>
      <AcFormInput
        register={'email'}
        Component={
          <AcEmailInput
            label={LABELS.EMAIL_ADDRESS}
            required
          />
        }
      />
      <AcFormInput
        register={'password'}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.NEW_PASSWORD}
            required
          />
        }
      />
      <AcFormInput
        lastChild
        register={'password_confirmation'}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.CONFIRM_PASSWORD}
            required
          />
        }
      />
      <AcButton
        type="submit"
        variant="primary"
        loading={auth.loading}
        sx={{ marginTop: isMobile ? 'auto' : 6 }}
        fullWidth>
        {LABELS.CHANGE_PASSWORD_BUTTON}
      </AcButton>
    </AcFormProvider>
  )
})
