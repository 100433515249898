import { useMemo } from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcCheckboxGroup,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { useStore } from '@hooks'
import { useDateHelpers } from '@hooks'
import { IWeekDay } from '@typings'

export interface IAcLuncherWeeklyAttendanceForm {
  weekIdentifier: string
  deadline: string
  confirmed: boolean
  days: IWeekDay[]
}

export const AcLuncherWeeklyAttendanceForm = observer(
  ({
    weekIdentifier,
    deadline,
    confirmed,
    days,
  }: IAcLuncherWeeklyAttendanceForm) => {
    const { user, weeks, toaster } = useStore()

    const initial = confirmed
      ? { attendance: days }
      : {
          attendance: user.current?.profile.days,
        }

    const deadlinePassed = useMemo(() => {
      return dayjs(deadline).isBefore(dayjs())
    }, [deadline])

    const getWeekdayOptions = useMemo(() => {
      const keys = Object.entries(WEEKDAYS_LABELS)
      return keys.map(([key, value]) => ({
        id: key,
        value: key.toLowerCase(),
        title: value,
      }))
    }, [user.current])

    const renderButton = useMemo(() => {
      // if deadline is passed.
      if (deadlinePassed) return null

      const label = confirmed ? LABELS.SUBMIT_CHANGES : LABELS.CONFIRM
      return (
        <AcButton
          loading={user.isLoading}
          fullWidth
          type="submit">
          {label}
        </AcButton>
      )
    }, [user.isLoading, confirmed, deadlinePassed])

    const onSubmit = async (data: any) => {
      try {
        await user.updateWeekAttendance(weekIdentifier, data.attendance)
        toaster.success({
          content: LABELS.SAVED_ATTENDANCE,
          timeout: 2500,
        })
        weeks.getWeekAttendance()
      } catch (e) {
        toaster.error({
          content: 'Er is iets misgegaan, probeer opnieuw',
        })
      }
    }

    const renderDeadline = useMemo(() => {
      if (!deadline || deadlinePassed) return null
      const { prettyDateTime } = useDateHelpers(deadline)
      return prettyDateTime
    }, [deadline])

    return (
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}>
        <AcFormInput
          register="attendance"
          Component={
            <AcCheckboxGroup
              fullWidth
              id="attendance"
              options={getWeekdayOptions}
            />
          }
        />
        {renderDeadline && (
          <AcTypography
            size="sm"
            textAlign="center"
            pt={2}>
            {confirmed
              ? LABELS.CONFIRM_ATTENDANCE_EDIT_DEADLINE
              : LABELS.CONFIRM_ATTENDANCE_DEADLINE}{' '}
            {renderDeadline}.
          </AcTypography>
        )}
        {renderButton}
      </AcFormProvider>
    )
  },
)
