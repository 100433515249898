import { useState } from 'react'
import { Link } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

export const AcRequestNewPasswordForm = observer(() => {
  const { auth, toaster } = useStore()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)
  const onSubmit = async (values: any) => {
    try {
      await auth.requestPasswordReset(values.email)
      toaster.success({ content: LABELS.REQUEST_NEW_PASSWORD })
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcFormProvider
      initial={{ email: '' }}
      onSubmit={onSubmit}
      errors={errors}
      flexGrow={1}>
      <AcFormInput
        register={'email'}
        Component={
          <AcEmailInput
            required
            label={LABELS.EMAIL_ADDRESS}
          />
        }
      />
      <AcColumn
        spacing="xl"
        flexGrow={1}>
        <AcTypography removePadding>
          <Link to={NAVIGATE_TO.LOGIN}>{LABELS.REMEMBERED_PASSWORD}</Link>
        </AcTypography>
        <AcButton
          variant="primary"
          type="submit"
          fullWidth
          loading={auth.watchLoading}
          sx={{ marginTop: 'auto' }}>
          {LABELS.SEND}
        </AcButton>
      </AcColumn>
    </AcFormProvider>
  )
})
