import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { getFromStorage } from '@helpers'

const _api_ = process.env.REACT_APP_API_URL + '/api/portal'

const apiConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  maxContentLength: 10000,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Headers': 'Set-Cookie',
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
  },
  // withCredentials: true,
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers'],
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.authorization = `Bearer ${token}`
      return JSON.stringify(data)
    },
  ],
}

const downloadConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  responseType: 'blob',
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers'],
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.authorization = `Bearer ${token}`
      return data
    },
  ],
}

const uploadConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  maxContentLength: 10000,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    type: 'formData',
  },
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers'],
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.authorization = `Bearer ${token}`
      return data
    },
  ],
}

export default {
  api: apiConfig,
  download: downloadConfig,
  upload: uploadConfig,
}
