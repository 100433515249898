import { AcTable } from '@components'

export const AcListResults = () => {
  return (
    <AcTable
      head={['Spreads']}
      rows={[]}
    />
  )
}
