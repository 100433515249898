import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { extractAPIFieldErrors } from '@helpers'
import { Store } from '@stores'
import { IWeeksSingle } from '@typings'

export class WeeksStore {
  store: Store
  week: IWeeksSingle | null
  weekLunchers: any
  attendanceWeeks: any
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.week = null
    this.attendanceWeeks = null
    this.loading = false
    this.weekLunchers = null
  }

  @computed
  get current(): IWeeksSingle | null {
    return this.week
  }

  @computed
  get currentAttendanceWeeks() {
    return this.attendanceWeeks
  }

  @action
  setWeek = (week: IWeeksSingle) => {
    this.week = week
  }

  @action
  setWeekLunchers = (lunchers: IWeeksSingle) => {
    this.weekLunchers = lunchers
  }

  @action
  resetWeekLunchers = () => {
    this.weekLunchers = null
  }

  @action
  resetWeek = () => {
    this.week = null
  }

  @action
  getWeek = async (week: string) => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getWeek(week)
      this.setWeek(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  getWeekLunchers = async (week: string) => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getWeekLunchers(week)
      this.setWeekLunchers(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  getWeekAttendance = async () => {
    this.store.set(STORE_KEYS.WEEKS, 'loading', true)
    try {
      const { data } = await this.store.api.weeks.getAttendanceWeeks()
      this.store.set(STORE_KEYS.WEEKS, 'attendanceWeeks', data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.WEEKS, 'loading', false)
    }
  }

  @action
  reset = () => {
    this.week = null
    this.attendanceWeeks = null
    this.loading = false
    this.weekLunchers = null
  }
}
