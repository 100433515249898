import { useEffect, useMemo, useState } from 'react'
import { AcAnimatedFade } from '@animations'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import soupSaladBG from '@assets/visuals/soup_salad_card_bg.png'
import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcExtrasSwiper,
  AcIconButton,
  AcImage,
  AcRow,
  AcTag,
  AcTypography,
} from '@components'
import { DIALOGS, LABELS, THEME_COLORS } from '@constants'
import { AcFormatDate } from '@helpers'
import { useStore } from '@hooks'
import { useBreakpoints } from '@hooks'

interface IAcDashboardWeekTab {
  week: string
}

export const AcDashboardWeekTab = observer(({ week }: IAcDashboardWeekTab) => {
  const { orders, dialog, weeks, products, user } = useStore()
  const [doubleChart, setDoubleChart] = useState<any>(null)

  const { isMobile } = useBreakpoints()

  const flexDirection = useMemo(() => {
    return isMobile ? 'column' : 'row'
  }, [isMobile])

  const alignItems = useMemo(() => {
    return isMobile ? 'flex-start' : 'center'
  }, [isMobile])

  const lunchPreferencesButton = useMemo(() => {
    if (isMobile) {
      return (
        <AcIconButton
          sx={{ padding: 0 }}
          color="green">
          <TroubleshootOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        onClick={() => dialog.open(DIALOGS.LUNCH_PREFERENCES)}
        startIcon={<TroubleshootOutlinedIcon />}
        variant="text">
        {LABELS.LUNCH_PREFERENCES}
      </AcButton>
    )
  }, [isMobile])

  const renderWeekMenuCard = useMemo(() => {
    if (!user.hasSalad && !user.hasSoup) return null
    return (
      <AcCard
        removePadding
        sx={{
          flex: 1,
          border: 0,
          backgroundColor: THEME_COLORS.BRAND_PINK,
        }}
        cardColor="pink">
        <AcColumn
          gap={0}
          justifyContent="space-between"
          sx={{ height: '100%' }}>
          <AcColumn
            padding={5}
            gap={2}>
            <AcTypography
              removePadding
              component="h2">
              {LABELS.WEEK_MENU}
            </AcTypography>
            <AcRow gap={2}>
              {user.hasSoup && (
                <AcTag
                  large
                  color="white"
                  label="Salade"
                />
              )}
              {user.hasSalad && (
                <AcTag
                  large
                  color="white"
                  label="Soep"
                />
              )}
            </AcRow>
          </AcColumn>
          <AcImage
            sx={{ float: 'right', borderRadius: '8px', maxWidth: '100%' }}
            src={soupSaladBG}
          />
        </AcColumn>
      </AcCard>
    )
  }, [user.current])

  const reminderButton = useMemo(() => {
    if (isMobile) {
      return (
        <AcIconButton
          sx={{ padding: 0 }}
          onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
          color="green">
          <NotificationsActiveOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        startIcon={<NotificationsActiveOutlinedIcon />}
        onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
        variant="text">
        {LABELS.SEND_REMINDER}
      </AcButton>
    )
  }, [isMobile])

  const renderLegendRow = useMemo(() => {
    if (!weeks.current?.chart?.legend) return null

    return (
      <AcRow gap={6}>
        <AcColumn gap={0}>
          <AcTypography
            removePadding
            color="green.main"
            component="h3">
            30
          </AcTypography>
          <AcTypography color="green.main">
            {LABELS.AMOUNT_REGISTERED.toLowerCase()}
          </AcTypography>
        </AcColumn>
        <AcColumn gap={0}>
          <AcTypography
            removePadding
            color="green.main"
            component="h3">
            40%
          </AcTypography>
          <AcTypography color="green.main">
            {LABELS.CONFIRMED.toLowerCase()}
          </AcTypography>
        </AcColumn>
      </AcRow>
    )
  }, [weeks.current?.chart?.legend])

  useEffect(() => {
    weeks.resetWeek()
    weeks.getWeek(week)
    weeks.getWeekLunchers(week)

    products.getExtras(week)
  }, [])

  const renderOrderButton = useMemo(() => {
    // If deadline has been passed.
    if (dayjs(weeks.current?.deadline).isBefore(dayjs())) {
      return null
    }

    return (
      <>
        <AcTypography removePadding>
          {LABELS.FINALIZE_BY}{' '}
          {AcFormatDate(weeks.current?.deadline, '', 'DD MMMM H:mm')}
        </AcTypography>
        <AcButton>{LABELS.START_ORDERING}</AcButton>
      </>
    )
  }, [weeks.current])

  if (!weeks.week) {
    return null
  }

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 20 }}
      exit={{ x: 0, y: -20 }}>
      <AcColumn gap={6}>
        <AcColumn>
          <AcRow
            gap={0}
            alignItems={alignItems}
            sx={{ flexDirection, justifyContent: 'space-between' }}>
            <AcTypography
              color="green.main"
              removePadding
              component="h2">
              {LABELS.ORDER_WEEK} {weeks.current?.week}
            </AcTypography>
            <AcRow
              gap={5}
              alignItems={alignItems}
              sx={{ flexDirection }}>
              {renderOrderButton}
            </AcRow>
          </AcRow>

          <AcRow
            gap="16px"
            sx={{ flexDirection }}
            justifyContent="space-between">
            <AcCard
              sx={{
                flex: 2,
              }}>
              <AcColumn
                gap={5}
                sx={{
                  justifyContent: 'space-between',
                  height: '100%',
                }}>
                <AcRow
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <AcTypography
                    color="green.main"
                    component="h2">
                    {LABELS.AMOUNTS}
                  </AcTypography>
                  <AcRow gap={5}>
                    {lunchPreferencesButton}
                    {reminderButton}
                  </AcRow>
                </AcRow>
                {renderLegendRow}
                {weeks.current?.chart && (
                  <AcCharts chartData={weeks.current?.chart} />
                )}
              </AcColumn>
            </AcCard>
            {renderWeekMenuCard}
          </AcRow>
        </AcColumn>

        <AcColumn>
          <AcColumn gap={0}>
            <AcTypography
              removePadding
              color="green.main"
              component="h2">
              {LABELS.EXTRAS}
            </AcTypography>
            {!isMobile && (
              <AcTypography removePadding>{LABELS.EXTRAS_TEXT}</AcTypography>
            )}
          </AcColumn>
          <AcExtrasSwiper items={products.extras} />
        </AcColumn>
      </AcColumn>
    </AcAnimatedFade>
  )
})
