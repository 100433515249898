import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRadioGroup,
  AcRow,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'
import { IErrorResponse } from '@typings'

export interface IAcLuncherPreferenceForm {
  nextStep: boolean
}

const FormInputs = observer(({ nextStep }: IAcLuncherPreferenceForm) => {
  const navigate = useNavigate()
  const { isMobile } = useBreakpoints()
  const { user } = useStore()

  const options = [
    {
      id: 1,
      title: LABELS.MIX_EXTENDED,
      label: LABELS.MIX_EXPLAINER,
      value: KEYS.MIX,
    },
    {
      id: 2,
      title: LABELS.VEGETARIAN_EXTENDED,
      label: LABELS.VEGETARIAN_EXPLAINER,
      value: KEYS.VEGETARIAN,
    },
    {
      id: 3,
      title: LABELS.VEGAN_EXTENDED,
      label: LABELS.VEGAN_EXPLAINER,
      value: KEYS.VEGAN,
    },
  ]
  return (
    <>
      <AcFormInput
        register="preference"
        Component={
          <AcRadioGroup
            fullWidth
            id="luncher-preferences"
            options={options}
          />
        }
      />
      {!nextStep ? (
        <AcButton
          loading={user.loading}
          sx={{ marginTop: isMobile ? 'auto' : 2 }}
          type="submit"
          fullWidth>
          {LABELS.CONFIRM}
        </AcButton>
      ) : (
        <AcRow
          alignItems="center"
          justifyContent="space-between"
          marginTop={isMobile ? 'auto' : 2}>
          <AcButton
            type="button"
            onClick={() => navigate(-1)}
            variant="text"
            disabled={user.loading}
            startIcon={<AcIcon icon={ICONS.BACK} />}>
            {LABELS.BACK}
          </AcButton>
          <AcButton
            type="submit"
            loading={user.loading}
            endIcon={<AcIcon icon={ICONS.NEXT} />}>
            {LABELS.NEXT}
          </AcButton>
        </AcRow>
      )}
    </>
  )
})

export const AcLuncherPreferenceForm = ({
  nextStep,
}: IAcLuncherPreferenceForm) => {
  const navigate = useNavigate()
  const [errors, setErrors] = useState<any>(null)
  const { user, dialog, toaster } = useStore()

  const onSubmit = async (data: any) => {
    try {
      await user.patchPreference(data.preference)
      toaster.success({ content: LABELS.SAVED_PREFERENCE })
      if (nextStep) {
        navigate(NAVIGATE_TO.ONBOARDING_ALLERGIES)
        return
      }

      dialog.close()
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        toaster.error({ content: err.message })
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  const getDefaultValues = useMemo(() => {
    return {
      preference: user.current?.profile?.preference,
    }
  }, [user.current?.profile?.preference])

  return (
    <AcFormProvider
      initial={getDefaultValues}
      onSubmit={onSubmit}
      errors={errors}
      flexGrow={1}>
      <FormInputs nextStep={nextStep} />
    </AcFormProvider>
  )
}
