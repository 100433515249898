export const KEYS = {
  HOME: 'home',
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
  STATUS_CONFIRMED: 'confirmed',
  STATUS_FULFILLED: 'fulfilled',
  STATUS_PENDING: 'pending',
  STATUS_CANCELLED: 'cancelled',
} as const

export const STORE_KEYS = {
  TOASTER: 'toaster',
  DIALOG: 'dialog',
  USER: 'user',
  ONBOARDING: 'onboarding',
  ORDERS: 'orders',
  PRODUCTS: 'products',
  FORM: 'form',
  AUTH: 'auth',
  WEEKS: 'weeks',
} as const

export const PREFERENCE_KEYS = {
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
}
