import { useMemo } from 'react'

import { AcImage, AcTable, AcTypography } from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { formatPrice, getSum } from '@helpers'
import { IOrderDetailsOverviewExtraDay } from '@typings'

export const AcOrderExtrasPerDay = ({
  day,
  products,
}: IOrderDetailsOverviewExtraDay) => {
  const renderRows = useMemo(() => {
    if (!products?.length) return null

    return products.map(product => (
      <AcTable.Row>
        <AcTable.Cell
          align="left"
          style={{ width: 72 }}>
          {product.image?.url && (
            <AcImage
              src={product.image.url}
              srcset={product.image.srcset}
              maxWidth={72}
            />
          )}
        </AcTable.Cell>
        <AcTable.Cell align="left">
          <AcTypography component="span">{product.name}</AcTypography>
        </AcTable.Cell>
        <AcTable.Cell
          style={{ width: 100 }}
          align="right">
          <AcTypography component="span">{product.quantity}x</AcTypography>
        </AcTable.Cell>
        <AcTable.Cell
          style={{ width: 100 }}
          align="right">
          <AcTypography component="span">
            {formatPrice(product.price)}
          </AcTypography>
        </AcTable.Cell>
        <AcTable.Cell
          style={{ width: 100 }}
          align="right">
          <AcTypography component="span">
            {formatPrice(product.price * product.quantity)}
          </AcTypography>
        </AcTable.Cell>
      </AcTable.Row>
    ))
  }, [products.length])

  const renderSum = useMemo(() => {
    const dailyTotals = products.map(({ price, quantity }) => price * quantity)
    const dailySum = getSum(dailyTotals)
    return (
      <AcTable.Row>
        <AcTable.Cell
          align="left"
          colSpan={4}>
          <AcTypography
            color="green.main"
            component="span"
            bold>
            {LABELS.SUBTOTAL_EXCL_TAXES}
          </AcTypography>
        </AcTable.Cell>
        <AcTable.Cell
          align="right"
          style={{ width: 100 }}>
          <AcTypography
            color="green.main"
            component="span"
            bold>
            {formatPrice(dailySum)}
          </AcTypography>
        </AcTable.Cell>
      </AcTable.Row>
    )
  }, [products?.length])

  return (
    <>
      <AcTable.Row>
        <AcTable.Cell colSpan={5}>
          <AcTypography
            color="green.main"
            component="span"
            bold>
            {WEEKDAYS_LABELS[day.toUpperCase() as keyof typeof WEEKDAYS_LABELS]}
          </AcTypography>
        </AcTable.Cell>
      </AcTable.Row>
      {renderRows}
      {renderSum}
    </>
  )
}
