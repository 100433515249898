import { useEffect, useMemo, useState } from 'react'

import {
  AcAlert,
  AcColumn,
  AcIcon,
  AcLoaderPulse,
  AcRow,
  AcSelect,
  AcTable,
  AcTextInput,
} from '@components'
import { ICONS, LABELS } from '@constants'
import { useStore } from '@hooks'
import { AcNoLongerExcludedModal } from '@partials'
import { IPreferenceCategory, ISimpleProduct } from '@typings'

export const AcExcludedProductsTab = () => {
  const { products, dialog } = useStore()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [categories, setCategories] = useState<IPreferenceCategory[] | null>(
    null,
  )
  const [searchInput, setSearchInput] = useState('')
  const [categoryFilter, setCategoryFilter] = useState<string>('')

  const fetchPreferences = async () => {
    try {
      const res = await products.getPreferences()
      if (res) setCategories(res)
    } catch (error) {
      setError((error as any).message)
    } finally {
      setLoading(false)
    }
  }

  const openConfirmDialog = (product: ISimpleProduct) => {
    dialog.open({
      title: LABELS.NO_LONGER_EXCLUDE,
      content: (
        <AcNoLongerExcludedModal
          product={product}
          onSuccess={fetchPreferences}
        />
      ),
      maxWidth: 448,
    })
  }

  const renderTable = (cat: IPreferenceCategory) => {
    const { count, rows } = renderTableRows(cat.products)
    if (!count) return null
    return (
      <AcTable
        head={[`${cat.category} (${count})`]}
        rows={rows.length ? rows : null}
      />
    )
  }

  const renderTableRows = (products: IPreferenceCategory['products']) => {
    const matchingSearchInput = products.filter(prod =>
      prod?.name?.toLowerCase().includes(searchInput?.toLowerCase()),
    )
    const rows = matchingSearchInput.map(prod => (
      <AcTable.Row
        onClick={() => openConfirmDialog(prod)}
        icon={<AcIcon icon={ICONS.BIN} />}>
        <AcTable.Cell>{prod.name}</AcTable.Cell>
      </AcTable.Row>
    ))

    return { count: matchingSearchInput.length, rows }
  }

  useEffect(() => {
    fetchPreferences()
  }, [])

  if (error) return <AcAlert severity="error">{error}</AcAlert>

  const renderCategoryFilters = useMemo(() => {
    return (
      <AcRow justifyContent="space-between">
        <AcTextInput
          label={LABELS.SEARCH}
          onChange={e => setSearchInput(e.target.value)}
        />
        {categories && categories.length > 1 && (
          <AcSelect
            fullWidth={false}
            minWidth={250}
            id="category-filter"
            value={categoryFilter}
            label={LABELS.CATEGORIES}
            renderClearInput
            options={categories.map((item, i) => ({
              id: i + 1,
              value: item.category,
              label: item.category,
            }))}
            onChange={(item: string) => setCategoryFilter(item)}
          />
        )}
      </AcRow>
    )
  }, [categories?.length])

  const renderCategoryList = useMemo(() => {
    if (!categories?.length) return null
    const filteredCategories = categoryFilter
      ? categories.filter(({ category }) => category === categoryFilter)
      : categories
    return filteredCategories?.map(cat => renderTable(cat))
  }, [categories?.length, categoryFilter, searchInput])

  return (
    <AcColumn>
      {loading ? (
        <AcLoaderPulse />
      ) : (
        <AcColumn>
          {renderCategoryFilters}
          {renderCategoryList}
        </AcColumn>
      )}
    </AcColumn>
  )
}
