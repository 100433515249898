import { useMemo } from 'react'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import NotFoundVisual from '@assets/visuals/bb_404.png'
import {
  AcButton,
  AcColumn,
  AcContainer,
  AcImage,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

export const AcNotFoundView = observer(() => {
  const { auth } = useStore()

  const renderButton = useMemo(() => {
    if (!auth.is_authenticated) {
      return (
        <AcButton href={NAVIGATE_TO.LOGIN}>{LABELS.BACK_TO_LOGIN}</AcButton>
      )
    }

    return (
      <AcButton href={NAVIGATE_TO.ADMIN_DASHBOARD}>
        Terug naar dashboard
      </AcButton>
    )
  }, [auth.is_authenticated])

  return (
    <AcColumn
      height="100vh"
      alignItems={'center'}
      justifyContent={'center'}>
      <AcContainer>
        <AcColumn
          alignItems="center"
          justifyContent={'center'}>
          <AcImage
            src={NotFoundVisual}
            width={600}
            maxWidth="100%"
          />
          <AcTypography
            component="h1"
            textAlign="center">
            Oops! Deze pagina is net als een goede lunch... even genieten van
            een pauze!
          </AcTypography>
          <AcTypography textAlign="center">
            Je hebt een pagina gevonden die misschien net bezig is met een
            heerlijke lunch van De Buurtboer. Jammer genoeg kunnen we je niet
            serveren wat je zocht, maar we serveren wél de lekkerste maaltijden!
          </AcTypography>
          {renderButton}
        </AcColumn>
      </AcContainer>
    </AcColumn>
  )
})
