import SearchIcon from '@mui/icons-material/Search'

import {
  AcBox,
  AcButton,
  AcFormInput,
  AcFormProvider,
  AcRow,
  AcSelect,
  AcTextInput,
  AcTypography,
} from '@components'
import { OnChangeEvent } from '@typings'

export interface IAcListFilters {
  query: string
  setQuery: (value: string) => void
  filters?: object[]
  setFilters?: (e: any) => void
}

export const AcListFilters = ({
  query,
  setQuery,
  filters,
  setFilters,
}: IAcListFilters) => {
  const handleSearch = (e: OnChangeEvent) => {
    setQuery(e.target.value)
  }

  const formId = 'test'

  return (
    <AcBox>
      <AcRow justifyContent="space-between">
        <AcTextInput
          value={query}
          onChange={handleSearch}
          label="Zoeken"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />

        <AcRow alignItems="center">
          <AcSelect
            value={''}
            id={formId}
            label="Rollen"
            options={[]}
          />

          <AcSelect
            value={''}
            id={formId}
            label="Alle"
            options={[]}
          />
          <AcButton fullWidth>Uitnodigen</AcButton>
        </AcRow>
      </AcRow>
    </AcBox>
  )
}
