import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IAuthLoginResponse,
  IAuthUser,
  IDefaultRegistrationFields,
  ILoginUserPayload,
  IRegisterUserPayload,
  IUser,
} from '@typings'

export class AuthApi extends AxiosBaseApi {
  register = (payload: IRegisterUserPayload) =>
    this.api
      .post<{
        data: { data: IAuthUser }
      }>(ENDPOINTS.AUTHENTICATION.REGISTER, payload)
      .then(response => response.data)

  getRegistrationFields = (code: string, signature: string) =>
    this.api
      .get<{
        data: { data: IDefaultRegistrationFields }
      }>(
        ENDPOINTS.AUTHENTICATION.INVITE +
          `?code=${code}&signature=${signature}`,
      )
      .then(res => res.data.data)

  login = (payload: ILoginUserPayload) =>
    this.api
      .post<{
        data: {
          data: { id: number }
          meta: IAuthLoginResponse
        }
      }>(ENDPOINTS.AUTHENTICATION.LOGIN, payload)
      .then(response => response.data)

  requestPasswordReset = (payload: { email: string }) =>
    this.api
      .post<{
        data: null
      }>(ENDPOINTS.AUTHENTICATION.REQUEST_NEW_PASSWORD, payload)
      .then(response => response.data)

  resetPassword = (payload: {
    email: string
    password: string
    password_confirmation: string
    token: string
  }) =>
    this.api
      .post<{
        data: null
      }>(ENDPOINTS.AUTHENTICATION.RESET_PASSWORD, payload)
      .then(response => response.data)

  changePassword = (payload: {
    current_password: string
    password: string
    password_confirmation: string
  }) =>
    this.api
      .post<{
        data: null
      }>(ENDPOINTS.USER.CHANGE_PASSWORD, payload)
      .then(response => response.data)
}
