import { AcDefaultLayout, AcOnboardingLayout } from '@layouts'
import {
  AcNotFoundView,
  ComponentsView,
  CustomerProfileView,
  DashboardView,
  LoginView,
  LuncherAttendanceView,
  LuncherPreferencesOverviewView,
  OnboardingAllergiesView,
  OnboardingAttendanceView,
  OnboardingCompletedView,
  OnboardingPreferencesView,
  OnboardingRegisterView,
  OnboardingWelcomeView,
  OrderDetailView,
  OrdersView,
  RequestNewPasswordView,
  ResetPasswordView,
} from '@views'

import { KEYS } from '@constants'

import { NoAccessView } from '../views/default/no-access.view'

export const ROUTE_KEYS = {
  ROOT: '/',
  DASHBOARD_INDEX: '/dashboard',
  LUNCHER_INDEX: '/lunchers',
  ONBOARDING_INDEX: '/onboarding',
  AUTH_INDEX: '/auth',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password/:token',
  REQUEST_NEW_PASSWORD: 'request-new-password',
  NO_ACCESS: 'no-access',
  REGISTER: 'register',
  ATTENDANCE: 'attendance',
  PREFERENCES: 'preferences',
  OVERVIEW: 'overview',
  ALLERGIES: 'allergies',
  ORDERS: 'orders',
  PROFILE: 'profile',
  ONBOARDING_COMPLETED: 'onboarding-completed',
}

const {
  ROOT,
  DASHBOARD_INDEX,
  LUNCHER_INDEX,
  ONBOARDING_INDEX,
  AUTH_INDEX,
  REGISTER,
  LOGIN,
  ATTENDANCE,
  PREFERENCES,
  OVERVIEW,
  ALLERGIES,
  ORDERS,
  PROFILE,
  ONBOARDING_COMPLETED,
  RESET_PASSWORD,
  REQUEST_NEW_PASSWORD,
  NO_ACCESS,
} = ROUTE_KEYS

export const NAVIGATE_TO = {
  ONBOARDING: `${ONBOARDING_INDEX}`,
  ONBOARDING_WELCOME: `${ONBOARDING_INDEX}`,
  ONBOARDING_REGISTER: `${ONBOARDING_INDEX}/${REGISTER}`,
  ONBOARDING_PREFERENCES: `${ONBOARDING_INDEX}/${PREFERENCES}`,
  ONBOARDING_ALLERGIES: `${ONBOARDING_INDEX}/${ALLERGIES}`,
  ONBOARDING_ATTENDANCE: `${ONBOARDING_INDEX}/${ATTENDANCE}`,
  ONBOARDING_COMPLETED: `/${ONBOARDING_COMPLETED}`,
  ADMIN_DASHBOARD: `${DASHBOARD_INDEX}/${OVERVIEW}`,
  ADMIN_ORDERS: `${DASHBOARD_INDEX}/${ORDERS}`,
  ADMIN_PROFILE: `${DASHBOARD_INDEX}/${PROFILE}`,
  LUNCHER_OVERVIEW: `${LUNCHER_INDEX}/${OVERVIEW}`,
  LUNCHER_PREFERENCES: `${LUNCHER_INDEX}/${PREFERENCES}`,
  LUNCHER_ALLERGIES: `${LUNCHER_INDEX}/${ALLERGIES}`,
  LOGIN: `${AUTH_INDEX}/${LOGIN}`,
  RESET_PASSWORD: `${AUTH_INDEX}/${RESET_PASSWORD}`,
  REQUEST_NEW_PASSWORD: `${AUTH_INDEX}/${REQUEST_NEW_PASSWORD}`,
  NO_ACCESS: `${AUTH_INDEX}/${NO_ACCESS}`,
}

export const AUTHENTICATION_ROUTES = [
  NAVIGATE_TO.LOGIN,
  NAVIGATE_TO.RESET_PASSWORD,
  NAVIGATE_TO.REQUEST_NEW_PASSWORD,
]

export const ROUTER = [
  {
    id: 'NOT_FOUND',
    path: '*',
    element: <AcNotFoundView />,
  },
  {
    id: 'ROOT',
    path: ROOT,
    element: <AcOnboardingLayout variant="branded_table" />,
    children: [
      {
        index: true,
        element: <LoginView />,
      },
    ],
  },
  {
    id: 'COMPONENTS',
    path: '/components',
    element: <AcDefaultLayout variant="default" />,
    children: [
      {
        index: true,
        element: <ComponentsView />,
      },
    ],
  },
  {
    id: 'ONBOARDING',
    path: ONBOARDING_INDEX,
    element: <AcOnboardingLayout />,
    children: [
      {
        index: true,
        element: <OnboardingWelcomeView />,
      },
      {
        path: REGISTER,
        element: <OnboardingRegisterView />,
      },
      {
        path: ALLERGIES,
        element: <OnboardingAllergiesView />,
      },
      {
        path: ATTENDANCE,
        element: <OnboardingAttendanceView />,
      },
      {
        path: PREFERENCES,
        element: <OnboardingPreferencesView />,
      },
    ],
  },
  {
    ID: 'ONBOARDING_COMPLETED',
    path: ROOT,
    element: <AcOnboardingLayout withCard={false} />,
    children: [
      {
        path: ONBOARDING_COMPLETED,
        element: <OnboardingCompletedView />,
      },
    ],
  },
  {
    id: 'LUNCHERS',
    path: LUNCHER_INDEX,
    element: <AcDefaultLayout variant="branded" />,
    protected: true,
    children: [
      {
        path: PREFERENCES,
        element: <LuncherPreferencesOverviewView />,
      },
      {
        path: OVERVIEW,
        element: <LuncherAttendanceView />,
      },
    ],
  },
  {
    id: KEYS.HOME,
    path: DASHBOARD_INDEX,
    element: <AcDefaultLayout variant="default" />,
    protected: true,
    children: [
      {
        path: OVERVIEW,
        element: <DashboardView />,
      },
      {
        path: ORDERS,
        children: [
          {
            index: true,
            element: <OrdersView />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <OrderDetailView />,
              },
            ],
          },
        ],
      },
      {
        path: PROFILE,
        element: <CustomerProfileView />,
      },
    ],
  },
  {
    path: AUTH_INDEX,
    element: <AcOnboardingLayout variant="branded_table" />,
    children: [
      {
        path: LOGIN,
        element: <LoginView />,
      },
      {
        path: RESET_PASSWORD,
        element: <ResetPasswordView />,
      },
      {
        path: REQUEST_NEW_PASSWORD,
        element: <RequestNewPasswordView />,
      },
      {
        path: NO_ACCESS,
        element: <NoAccessView />,
      },
    ],
  },
  /** [ ROUTES ]
  /** [ ROUTE_KEYS ]
   * You can add ROUTE_KEYS by doing:
   * `hygen make route`
   */
]
