import React, { useEffect, useMemo, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InputAdornment } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import {
  AcCheckboxDefault,
  AcHelpertext,
  AcIconButton,
  AcRow,
  AcTag,
} from '@components'

export interface IAcSelectOption {
  id: number | string
  value: string
  label: string
}

export interface IAcSelectBase {
  id: string
  label: string
  fullWidth?: boolean
  minWidth?: number
  options: IAcSelectOption[]
  startIcon?: React.ReactNode
  disabled?: boolean
  helperText?: string
  error?: string
  renderClearInput?: boolean
  onClearInput?: () => void
}

export interface IAcSelectSingle extends IAcSelectBase {
  allowMultiple?: false
  value: string
  onChange?: (value: string) => void
}

export interface IAcSelectMultiple extends IAcSelectBase {
  allowMultiple?: true
  value: string[]
  onChange?: (value: string[]) => void
}

export type IAcSelect = IAcSelectSingle | IAcSelectMultiple

export const AcSelect = ({
  id,
  label,
  value,
  fullWidth = true,
  minWidth = 100,
  options,
  onChange,
  startIcon,
  disabled,
  helperText,
  error,
  allowMultiple = false,
  renderClearInput,
}: IAcSelect) => {
  const [val, setVal] = useState<IAcSelect['value'] | undefined>(value)

  useEffect(() => {
    if (value !== val && onChange) onChange(val as string & string[])
  }, [val])

  const renderClearInputButton = useMemo(() => {
    if (!val || !options?.length || !renderClearInput) return null
    return (
      <InputAdornment
        sx={{ marginRight: 4 }}
        position="end">
        <AcIconButton
          onClick={() => setVal(undefined)}
          sx={{ opacity: 0.5 }}>
          <ClearIcon fontSize="small" />
        </AcIconButton>
      </InputAdornment>
    )
  }, [val, options.length])

  const renderStartIcon = useMemo(() => {
    if (!startIcon) return null
    return <InputAdornment position="start">{startIcon}</InputAdornment>
  }, [startIcon])

  const renderValue = (selected: IAcSelect['value']) => {
    return (
      <AcRow gap={1}>
        {typeof selected === 'string'
          ? selected
          : selected.map(item => <AcTag label={item} />)}
      </AcRow>
    )
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        id={id}
        value={val}
        label={label}
        onChange={e => setVal(e.target.value)}
        disabled={disabled}
        multiple={allowMultiple}
        IconComponent={ExpandMoreIcon}
        renderValue={renderValue}
        endAdornment={renderClearInputButton}
        startAdornment={renderStartIcon}
        MenuProps={{
          sx: {
            '.MuiMenu-paper': {
              backgroundColor: 'white.main',
            },
          },
        }}
        sx={{
          minWidth: minWidth,
          outlineColor: error && 'error.main',
          outlineWidth: error && 2,
        }}>
        {options.map(option => (
          <MenuItem
            key={option.id}
            value={option.value}
            sx={theme => ({
              fontSize: theme.typography.fontSize,
              maxHeight: theme.spacing(4),
              paddingBlock: theme.spacing(2),
            })}>
            {allowMultiple ? (
              <AcCheckboxDefault
                value={option.value}
                label={option.label}
                checked={val?.includes(option.value)}
              />
            ) : (
              option.label
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <AcHelpertext text={helperText} />}
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </FormControl>
  )
}
