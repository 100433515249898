import { useEffect, useState } from 'react'

import { AcList } from '@components'

export const AcUserManagementTab = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(null)

  const fetchUsers = async () => {
    console.log('fetching...')
  }

  useEffect(() => {
    fetchUsers()
  }, [])
  return <AcList />
}
