import { useEffect, useMemo, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { observer } from 'mobx-react-lite'
import type { Swiper as SwiperProps } from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  AcBox,
  AcCard,
  AcColumn,
  AcIconButton,
  AcRow,
  AcTypography,
} from '@components'
import { getDateRange } from '@helpers'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'
import { AcLuncherWeeklyAttendanceForm } from '@partials'

import 'swiper/css'
import './swiper-styles.scss'

export const AcAttendanceSwiper = observer(() => {
  const [swiperEl, setSwiperEl] = useState<SwiperProps | null>(null)
  const { isMobile, isDesktop } = useBreakpoints()
  const { weeks } = useStore()

  const attendanceWeeks = useMemo(() => {
    return weeks.currentAttendanceWeeks?.map(
      (attendanceWeek: any, i: number) => {
        return {
          title: `Week ${attendanceWeek.week}`,
          weekRange: getDateRange(attendanceWeek.week, attendanceWeek.year),
          deadline: attendanceWeek.deadline,
          week: attendanceWeek.week,
          year: attendanceWeek.year,
          confirmed: attendanceWeek.is_confirmed,
          days: attendanceWeek.days,
        }
      },
    )
  }, [weeks.currentAttendanceWeeks])

  useEffect(() => {
    weeks.getWeekAttendance()
  }, [])

  const handleNext = () => {
    swiperEl?.slideNext()
  }

  const handlePrev = () => {
    swiperEl?.slidePrev()
  }

  if (!attendanceWeeks) return null

  const renderSlides = useMemo(() => {
    if (!attendanceWeeks.length) return null
    return attendanceWeeks?.map((slide: any, i: number) => (
      <SwiperSlide key={i}>
        <AcCard
          maxWidth={448}
          center>
          <AcRow
            pb={5}
            justifyContent="space-between"
            alignItems="flex-start">
            {!isMobile && (
              <AcIconButton
                disabled={i === 0}
                color="green"
                onClick={handlePrev}>
                <ArrowBackIcon />
              </AcIconButton>
            )}
            <AcBox>
              <AcTypography
                color="green.main"
                removePadding
                component="h3">
                {slide.title}
              </AcTypography>
              <AcTypography
                color="#6F6F6F"
                textAlign="center"
                removePadding>
                {slide.weekRange}
              </AcTypography>
            </AcBox>
            {!isMobile && (
              <AcIconButton
                color="green"
                onClick={handleNext}
                disabled={i === attendanceWeeks?.length - 1}>
                <ArrowForwardIcon />
              </AcIconButton>
            )}
          </AcRow>
          {slide.confirmed && (
            <AcColumn mb={5}>
              <AcCard cardColor="beige">
                <AcRow>
                  <CheckCircleIcon />
                  <AcTypography
                    size="md"
                    sx={{
                      lineHeight: 1.6,
                    }}
                    removePadding
                    alignItems="center">
                    Bevestigd
                  </AcTypography>
                </AcRow>
              </AcCard>
            </AcColumn>
          )}
          <AcLuncherWeeklyAttendanceForm
            weekIdentifier={`${slide.week}-${slide.year}`}
            deadline={slide.deadline}
            confirmed={slide.confirmed}
            days={slide.days}
          />
        </AcCard>
      </SwiperSlide>
    ))
  }, [handlePrev, handleNext, isMobile, weeks.currentAttendanceWeeks])

  return (
    <AcBox className="ac-attendance-swiper">
      <Swiper
        spaceBetween={12}
        slidesPerView={isDesktop ? 3 : 1.2}
        centeredSlides
        modules={[Navigation]}
        onSwiper={setSwiperEl}>
        {renderSlides}
      </Swiper>
    </AcBox>
  )
})
