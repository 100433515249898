import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { LABELS } from '@constants'
import { useStore } from '@hooks'
import { IRoleEnum } from '@typings'

import { Tab, Tabs } from './StyledRoleSwitcher'

const ROLES = ['LUNCHER', 'ADMIN']

export const AcRoleSwitcher = observer(() => {
  const navigate = useNavigate()
  const { user } = useStore()

  const handleChange = (e: any) => {
    const newRole: IRoleEnum = e.target?.dataset?.role
    user.setRole(newRole)

    if (newRole === 'ADMIN') navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
    if (newRole === 'LUNCHER') navigate(NAVIGATE_TO.LUNCHER_OVERVIEW)
  }

  if (!user.watchUserRoles?.length) return null

  return (
    <Tabs
      value={ROLES.findIndex(role => role === user.watchActiveRole)}
      onChange={handleChange}
      aria-label={LABELS.SWITCH_ROLE}>
      {ROLES.map(role => (
        <Tab
          data-role={role}
          label={LABELS[role as keyof typeof LABELS]}
        />
      ))}
    </Tabs>
  )
})
