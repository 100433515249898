import { AcCard, AcColumn, AcImage, AcTypography, IAcCard } from '@components'
import { formatPrice } from '@helpers'
import { useBreakpoints } from '@hooks'

interface IAcExtrasCard extends IAcCard {
  title: string
  // TODO: SRCSET? Need to see payload.
  image?: {
    url: string
    srcset?: string
  }
  subtitle?: string
  price?: number
}

export const AcExtrasCard = ({
  image,
  title,
  subtitle,
  price,
  ...props
}: IAcExtrasCard) => {
  const { isMobile } = useBreakpoints()

  return (
    <AcCard
      sx={{
        border: 0,
        width: isMobile ? 239 : 264,
        display: 'flex',
      }}
      {...props}>
      <AcColumn
        gap={4}
        sx={{
          justifyContent: 'space-between',
          alignSelf: 'stretch',
        }}>
        {image && (
          <AcImage
            {...image}
            src={image.url}
            sx={{
              maxWidth: '100%',
              aspectRatio: '16/9',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
        )}
        <AcColumn
          paddingInline={2}
          paddingBlockEnd={2}>
          <AcColumn gap={2}>
            <AcTypography
              removePadding
              bold
              component="p"
              size={'mdlg'}>
              {title}
            </AcTypography>
            <AcTypography
              removePadding
              size="md"
              component="p">
              {subtitle}
            </AcTypography>
          </AcColumn>
          {price && (
            <AcTypography
              removePadding
              variant="h2"
              component="p"
              size="xl">
              {formatPrice(price)}
            </AcTypography>
          )}
        </AcColumn>
      </AcColumn>
    </AcCard>
  )
}
