import { useEffect, useMemo, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton } from '@mui/material'

import { AcTextInput, IAcTextInput } from '@components'
import { useBreakpoints } from '@hooks'

import { AcStyledNumberInput } from './AcStyledNumberInput'

type Omitted = 'variant'

export interface IAcNumberInput extends Omit<IAcTextInput, Omitted> {
  variant?: 'styled' | 'default'
  min?: number
  max?: number
  step?: number
  value?: number
}

export const AcNumberInput = ({
  variant = 'default',
  min,
  max,
  step = 1,
  value = 0,
  onChange,
  ...props
}: IAcNumberInput) => {
  const { isMobile } = useBreakpoints()
  const wrapper = useRef<HTMLDivElement | null>(null)

  const subtract = () => {
    const inputRef = wrapper.current?.querySelector('input')
    if (inputRef) {
      const currentValue = parseFloat(inputRef.value) || min || value
      if (!min || (min && currentValue > min)) {
        const newVal = currentValue - step
        inputRef.value = newVal.toString()
        inputRef.dispatchEvent(new Event('change', { bubbles: true }))
      }
    }
  }

  const add = () => {
    const inputRef = wrapper.current?.querySelector('input')
    if (inputRef) {
      let currentValue = parseFloat(inputRef.value) || value
      if (!max || (max && currentValue < max)) {
        if (min && currentValue < min) {
          inputRef.value = min.toString()
          inputRef.dispatchEvent(new Event('input', { bubbles: true }))
          return
        }
        const newVal = currentValue + step
        inputRef.value = newVal.toString()
        inputRef.dispatchEvent(new Event('input', { bubbles: true }))
      }
    }
  }

  const inputType = useMemo(() => {
    return isMobile ? 'tel' : 'number'
  }, [isMobile])

  if (variant === 'styled') {
    return (
      <AcStyledNumberInput
        ref={wrapper}
        type={inputType}
        onChange={onChange}
        startAdornment={
          <IconButton onClick={subtract}>
            <RemoveIcon sx={{ color: 'green.main' }} />
          </IconButton>
        }
        endAdornment={
          <IconButton onClick={add}>
            <AddIcon sx={{ color: 'green.main' }} />
          </IconButton>
        }
        inputProps={{
          min,
          max,
          step,
        }}
      />
    )
  }
  return (
    <AcTextInput
      type={inputType}
      onChange={onChange}
      InputProps={{
        inputProps: {
          min,
          max,
          step,
        },
      }}
      {...props}
    />
  )
}
