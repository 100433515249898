import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import('dayjs/locale/nl')
import isoWeek from 'dayjs/plugin/isoWeek'
import weekOfYear from 'dayjs/plugin/weekOfYear'

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

const DEFAULT_FORMAT = 'DD MMM Y'

export const AcFormatDate = (
  input: string | undefined,
  from = 'DD-MM-YYYY',
  to = DEFAULT_FORMAT,
) => {
  dayjs.extend(localizedFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(advancedFormat)
  dayjs.extend(weekOfYear)
  dayjs.extend(isoWeek)
  dayjs.locale('nl')

  // Verify valid date / dayjs object
  if (!dayjs(input).isValid()) return input

  const format = null
  const _from = from ? from : format

  // Format input
  return _from ? dayjs(input, _from).format(to) : dayjs(input).format(to)
}

export const AcDateFromWeekAndYear = (
  week: number,
  year: number,
  dayOfWeek: string,
  format?: string,
) => {
  dayjs.extend(localizedFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(advancedFormat)
  dayjs.extend(weekOfYear)
  dayjs.extend(isoWeek)
  dayjs.locale('nl')
  // get month from week
  const date = dayjs().week(week).year(year).day(DAYS.indexOf(dayOfWeek))
  return date.format(format)
}

export const getDateRange = (week: number, year: number) => {
  const start = AcFormatDate(
    AcDateFromWeekAndYear(week, year, 'monday'),
    '',
    'DD MMMM',
  )
  const end = AcFormatDate(
    AcDateFromWeekAndYear(week, year, 'friday'),
    '',
    'DD MMMM',
  )
  return `${start} t/m ${end}`
}

export default AcFormatDate
