import { useMemo } from 'react'
import { generatePath, useSearchParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'

import WelcomeVisual from '@assets/visuals/onboarding_welcome.webp'
import { AcBox, AcButton, AcColumn, AcImage, AcTypography } from '@components'
import { LABELS } from '@constants'

export const OnboardingWelcomeView = () => {
  const [params] = useSearchParams()

  const registerHref = useMemo(() => {
    const code = params.get('code')
    const signature = params.get('signature')
    return `${NAVIGATE_TO.ONBOARDING_REGISTER}?code=${code}&signature=${signature}`
  }, [params])

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexGrow: 1 }}>
      <AcColumn
        alignItems="center"
        textAlign="center"
        gap={0}>
        <AcTypography
          removePadding
          component="h2"
          color="orange.main">
          {LABELS.WELCOME}
        </AcTypography>
        <AcTypography
          component="h2"
          color="green.main"
          padding="sm">
          Gebruik deze app om door te geven of je mee luncht en geef je
          voorkeuren door
        </AcTypography>
        <AcTypography padding="lg">
          Binnenkort geniet je van een gevarieerde en lokale lunch van de
          Buurtboer
        </AcTypography>
        <AcBox pb={6}>
          <AcImage src={WelcomeVisual} />
        </AcBox>
        <AcButton
          sx={{ marginTop: 'auto' }}
          fullWidth
          href={registerHref}>
          {LABELS.REGISTER}
        </AcButton>
      </AcColumn>
    </AcAnimatedFade>
  )
}
